import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from '../../../context/User';
import styled from 'styled-components';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import TopBar from '../../Topbar';
import { Outlet } from 'react-router-dom';
import { ThirdBackground } from '../../../Theme/colorsVariables';
import { ExpandIcon } from '../../Sidebar/styles';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: column;
`;

const Header = styled.header`
  width: 100%;
  background-color: #3f51b5;
  color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const SidebarContainer = styled('div')<{
  $collapsed: 'true' | 'false';
  $isMobile: 'true' | 'false';
}>`
  width: ${({ $collapsed, $isMobile }) =>
    $collapsed === 'true' ? '30px' : $isMobile === 'true' ? '200px' : '250px'};
  min-width: ${({ $collapsed, $isMobile }) =>
    $collapsed === 'true' ? '30px' : $isMobile === 'true' ? '200px' : '250px'};
  background-color: #f1f1f1;
  position: relative;
  transition: width 0.3s ease;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`;

const MainContent = styled('main')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${ThirdBackground};
  padding: 0 20px 20px;
  overflow: hidden auto;
  transition: width 0.3s ease;
`;

const CollapseButton = styled(Box)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: -8px;
  top: 26px;
  z-index: 8;
  cursor: pointer;
`;

export default function AppContent() {
  const user = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  if (!user) {
    return <></>;
  }

  return (
    <LayoutContainer>
      <Header>
        <TopBar />
      </Header>
      <BodyContainer>
        <SidebarContainer
          $collapsed={collapsed ? 'true' : 'false'}
          $isMobile={isMobile ? 'true' : 'false'}
        >
          <CollapseButton onClick={() => setCollapsed(!collapsed)}>
            <ExpandIcon />
          </CollapseButton>
          <Sidebar
            open={!collapsed}
            onClose={() => {
              if (isMobile) setCollapsed(!collapsed);
            }}
          />
        </SidebarContainer>
        <MainContent>
          <Outlet />
          <Footer />
        </MainContent>
      </BodyContainer>
    </LayoutContainer>
  );
}
