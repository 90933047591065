import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppRolesByType } from '../../../services/sellerServices';
import RoleType from '../../../types/businessRoles';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

const RoleTypeName = {
  1: 'Seller/Seller Group Contact Classification',
  3: 'OnWise User Role',
  4: 'Agent Contact Classification',
  5: 'Provider Contact Classification',
};

export default function RoleTypeRole() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const { data: rolesByType1, isFetching: isFetching1 } = useAppRolesByType(RoleType.business, {
    enabled: isEnabled,
  });
  const { data: rolesByType3, isFetching: isFetching2 } = useAppRolesByType(RoleType.onWiseUser, {
    enabled: isEnabled,
  });
  const { data: rolesByType4, isFetching: isFetching3 } = useAppRolesByType(RoleType.agencyUser, {
    enabled: isEnabled,
  });
  const { data: rolesByType5, isFetching: isFetching4 } = useAppRolesByType(
    RoleType.businessWithOther,
    {
      enabled: isEnabled,
    },
  );

  const allRoles = useMemo(() => {
    if (rolesByType1 && rolesByType3 && rolesByType4 && rolesByType5) {
      return [
        ...rolesByType1.map((item) => ({ ...item, roleTypeName: RoleTypeName[1], roleTypeId: 1 })),
        ...rolesByType3.map((item) => ({ ...item, roleTypeName: RoleTypeName[3], roleTypeId: 3 })),
        ...rolesByType4.map((item) => ({ ...item, roleTypeName: RoleTypeName[4], roleTypeId: 4 })),
        ...rolesByType5.map((item) => ({ ...item, roleTypeName: RoleTypeName[5], roleTypeId: 5 })),
      ];
    }
    return [];
  }, [rolesByType1, rolesByType3, rolesByType4, rolesByType5]);

  const rows = useMemo(() => {
    return allRoles?.map((item) => ({
      ...item,
      id: item.roleId,
    }));
  }, [allRoles]);
  console.log(rows);

  const columns: GridColDef[] = [
    { field: 'roleTypeName', headerName: 'Role Type Name' },
    { field: 'roleName', headerName: 'Role Name' },
    { field: 'description', headerName: 'Description' },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['roleTypeName', 'roleName', 'description'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, allRoles]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Role Type Role" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'roleTypeName', sort: 'asc' }],
          },
        }}
        loading={isFetching1 || isFetching2 || isFetching3 || isFetching4}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
