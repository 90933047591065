import { useTranslation } from 'react-i18next';
import React, {
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { Grid, Typography } from '@mui/material';
import CustomTable from '../../components/shared/CustomTable';
import {
  AdvancedSearchFieldType,
  AdvancedSearchParam,
  Cell,
  CellType,
  InputsComponentProps,
} from '../../types/customTable';
import useDebounce from '../../hooks/useDebounce';
import { EntityType, Order } from '../../types/query-params';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { defaultAgenciesValues } from '../../data/defaultAdvancedSearchFormValues';
import { linkColor } from '../../Theme/colorsVariables';
import AuthService from '../../services/authService';
import { AdvanceSearchParams, AgencyData, PayloadAdvanceSearch, AddressState } from '../../types';
import AdvancedSearchInputs from '../../components/shared/CustomTableSearchFields';
import { getSearchString, isNumber } from '../../utils';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import { normalizeZip } from '../../utils/normalizeZip';
import { useAllAgents } from '../../services/agentService';
import { useQuery } from '../../hooks/useQuery';
import { ConfigurationService } from '../../services/configurationService';
import { FetchLookups } from '../../services/sellerServices';
import { QuickTableFilter } from '../../components/shared/CustomTableFilters/interface';
import { AccountOwnerService } from '../../services/accountOwner';
import { UserContext } from '../../context/User';
import { AppPhoneInputViewOnly } from '../../components/shared/AppPhoneFieldViewOnly';

const SEARCH_KEY = 'agentList.search';

function AgentsList() {
  const user = useContext(UserContext);
  const isViewAgencyPermission = usePermissions(PermissionKeys.ReadAgent);
  const isReadSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const { t } = useTranslation();
  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    accountOwner,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setAccountOwner,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const isAdvancedSearchEnabled = useQuery('advancedSearchVisibility');

  const [agencyTableRows, setAgencyTableRows] = useState<Cell[][]>([]);
  const debouncedSearchTerm: string = useDebounce(searchTerm, 300);
  const [totalCount, setTotalCount] = useState(0);

  const [advancedSearchVisibility, setAdvancedSearchVisibility] = useState(
    !!isAdvancedSearchEnabled && isAdvancedSearchEnabled === 'true',
  );
  const [dataAdvancedSearch, setDataAdvancedSearch] =
    useState<PayloadAdvanceSearch<AdvanceSearchParams>>();

  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);

  const { data: lookups } = FetchLookups();

  const { data: accountOwners } = AccountOwnerService.useAll();

  const { data: agencies, isLoading: isAgenciesLoading } = useAllAgents({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy,
    sortDirection: order,
    name: getSearchString(debouncedSearchTerm),
    advancedSearch: advancedSearchVisibility
      ? dataAdvancedSearch
      : { phone: isNumber(debouncedSearchTerm) ? debouncedSearchTerm : '' },
    agentId: advancedSearchVisibility
      ? dataAdvancedSearch?.agentId
      : getSearchString(debouncedSearchTerm),
    shouldMatchAllCriteria: advancedSearchVisibility,
    accountOwnerId:
      accountOwner?.value?.toString() !== EntityType.Any
        ? accountOwner?.value?.toString()
        : undefined,
  });

  const valueRef = useRef<any>();

  const tableHeadingsArray = useMemo(() => {
    const res: Record<string, string>[] = [
      { Agent: 'orgName' },
      { 'Agent ID': 'agentId' },
      { Phone: 'phone' },
      { ZIP: 'PhysicalZipCode' },
      { State: 'PhysicalState' },
      { City: 'PhysicalCity' },
      {
        [(lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value) || 'Account Owner']:
          'accountOwnerName',
      },
      { Sellers: 'sellerCount' },
    ];
    return res;
  }, [lookups?.accountOwnerLabel]);

  const { data: statesResponse } = AuthService.useStatesQuery();
  const states = useMemo<AddressState[]>(() => statesResponse || [], [statesResponse]);
  const { data: legalEntityTypes } = ConfigurationService.useLegalEntityTypes();

  const legalOrganizationOptions = useMemo(
    () =>
      legalEntityTypes?.map((item) => {
        return { value: item.legalEntityTypeId, text: item.legalEntityTypeDisplayName };
      }),
    [legalEntityTypes],
  );

  const accountOwnerOptions = useMemo(() => {
    if (user?.restrictAccessToOwnAgents) {
      //  Account Owner case
      setAccountOwner({ value: t('status.any'), label: `${user?.firstName} ${user?.lastName}` });
      return [{ value: t('status.any'), text: `${user?.firstName} ${user?.lastName}` }];
    }
    return [
      { value: t('status.any'), text: t('status.any') },
      ...(accountOwners?.map((item) => ({
        value: item.id.toString(),
        text: item.firstName + ' ' + item.lastName,
      })) || []),
    ];
  }, [
    accountOwners,
    setAccountOwner,
    t,
    user?.firstName,
    user?.lastName,
    user?.restrictAccessToOwnAgents,
  ]);

  const advancedSearchInputParams: AdvancedSearchParam[] = useMemo(() => {
    return [
      {
        label: `${t('organization.fieldLabel.legalName')}`,
        name: 'name',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.agentId')}`,
        name: 'agentId',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('security.users.header.table.phone')}`,
        name: 'phone',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.legalOrganization')}`,
        name: 'organizationType',
        type: AdvancedSearchFieldType.Select,
        selectTypeParams: legalOrganizationOptions,
      },
      {
        label: `empty1`,
        name: 'empty1',
        type: AdvancedSearchFieldType.EmptySpace,
        selectTypeParams: null,
      },
      {
        label: `empty2`,
        name: 'empty2',
        type: AdvancedSearchFieldType.EmptySpace,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicaladdress1')}`,
        name: 'physicalAddress1',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicalzipcode')}`,
        name: 'physicalZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalcity')}`,
        name: 'physicalCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalstate')}`,
        name: 'physicalState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
      {
        label: `${t('relationship.sellergroup.table.mailingaddress1')}`,
        name: 'mailingAddress1',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.mailingzipcode')}`,
        name: 'mailingZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingcity')}`,
        name: 'mailingCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingstate')}`,
        name: 'mailingState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
    ];
  }, [t, legalOrganizationOptions, states]);

  const selectArray: QuickTableFilter[] = useMemo(() => {
    return [
      {
        label:
          (lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value) || 'Account Owner',
        value: accountOwner,
        handleFilter: (newValue: { label: string; value: string }) => {
          setAccountOwner(newValue);
          setPage(0);
          setOrder(Order.ASC);
          setOrderBy('orgName');
        },
        filterOptions: accountOwnerOptions,
        type: 'single',
        disabled: user?.restrictAccessToOwnAgents,
      },
    ];
  }, [
    accountOwner,
    accountOwnerOptions,
    lookups?.accountOwnerLabel,
    setAccountOwner,
    setOrder,
    setOrderBy,
    setPage,
    user?.restrictAccessToOwnAgents,
  ]);

  const agencyTableRowsMapper = useCallback(
    (data: AgencyData[]) => {
      const getPhysicalAddress = (item: AgencyData) => {
        const physicalAddress = item.addresses.filter((x) => x.orgAddressTypeId === 1)[0];
        return physicalAddress;
      };
      const mappedCells = data.map((item) => [
        {
          data: item.orgName,
          type: CellType.Link,
          linkTo: `/dashboard/Agent/${item?.orgId}`,
          styles: { color: linkColor },
        },
        {
          data: item.agentId,
          type: CellType.Info,
        },
        {
          data:
            getPhysicalAddress(item)?.phoneNumber !== '' &&
            getPhysicalAddress(item)?.phoneNumber !== undefined &&
            getPhysicalAddress(item)?.phoneNumber !== null ? (
              <AppPhoneInputViewOnly
                phone={
                  (getPhysicalAddress(item)?.phoneNumberCountryCode?.replace('+', '') || '') +
                  (getPhysicalAddress(item)?.phoneNumber || '')
                }
              />
            ) : (
              ''
            ),
          type: CellType.Info,
        },
        {
          data: normalizeZip(getPhysicalAddress(item)?.zip),
          type: CellType.Info,
        },
        {
          data: states.find((state) => state.addressStateId === getPhysicalAddress(item)?.stateId)
            ?.stateName,
          type: CellType.Info,
        },
        {
          data: getPhysicalAddress(item)?.city,
          type: CellType.Info,
        },
        {
          data: (item.accountOwner?.firstName || '') + ' ' + (item.accountOwner?.lastName || ''),
          type: CellType.Info,
        },
        {
          data: `${item.sellerCount} Sellers`,
          type: isReadSellerPermission ? CellType.Link : CellType.Info,
          linkTo: item?.sellerCount > 0 ? `/dashboard/Seller?agentId=${item?.orgId}` : '',
          styles: { color: isReadSellerPermission ? linkColor : '' },
        },
      ]);
      setAgencyTableRows(mappedCells);
    },
    [isReadSellerPermission, states],
  );

  useEffect(() => {
    if (agencies) {
      agencyTableRowsMapper(agencies.data);
      setTotalCount(agencies.totalCount);
    }
  }, [agencies, agencyTableRowsMapper]);

  useEffect(() => {
    if (!advancedSearchVisibility) {
      setDataAdvancedSearch({
        name: '',
        advancedSearch: {
          phone: '',
          agentId: '',
          physicalAddress: '',
          physicalCity: '',
          physicalStateId: undefined,
          physicalZipCode: '',
          mailingAddress: '',
          mailingCity: '',
          mailingStateId: undefined,
          mailingZipCode: '',
          legalEntityTypeId: undefined,
        },
      });
    } else {
      setSearchTerm('');
    }
  }, [advancedSearchVisibility, setSearchTerm]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  const onSubmitAdvancedSearch = (data: any) => {
    const obj = {
      pageNumber: page + 1,
      pageSize: rowsPerPage,
      sortColumn: orderBy,
      sortDirection: order,
      name: data.name ?? undefined,
      phone: data.phone?.replaceAll('-', ''),
      agentId: data.agentId ?? undefined,
      physicalAddress: data.physicalAddress1,
      physicalCity: data.physicalCity ?? undefined,
      physicalStateId:
        data.physicalState?.label === 'Any' ? '' : data.physicalState?.value?.addressStateId,
      physicalZipCode: data.physicalZipCode ?? undefined,
      mailingAddress: data.mailingAddress1,
      mailingCity: data.mailingCity ?? undefined,
      mailingStateId:
        data.mailingState?.label === 'Any' ? '' : data.mailingState?.value?.addressStateId,
      mailingZipCode: data.mailingZipCode ?? undefined,
      legalEntityTypeId: data.organizationType?.value,
    };
    setDataAdvancedSearch(obj);

    setPage(0);
  };

  const handleRowsPerPageChange = (newCount: number) => {
    setRowsPerPage(newCount);
    setPage(0);
  };

  const handleRequestSort = (newOrder: Order, newOrderBy: string): void => {
    if (!agencyTableRows) return;
    setOrder(newOrder);

    if (
      newOrderBy ===
      ((lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value) || 'Account Owner')
    ) {
      setOrderBy('accountOwnerName');
      return;
    }
    setOrderBy(newOrderBy);
  };

  const handleCloseButton = () => {
    setPage(0);
    onSubmitAdvancedSearch(defaultAgenciesValues);
  };

  if (!isViewAgencyPermission) {
    return (
      <Grid container textAlign="center">
        <Grid item md={12} xs={12}>
          <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
            {t('restrictions.tooltip')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <CustomTable
        setPagination={setPagination}
        isDataLoading={isAgenciesLoading}
        page={page}
        rowsPerPage={rowsPerPage}
        data={agencyTableRows}
        valueRef={valueRef}
        goError={false}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        setPage={setPage}
        tableHeadingsArray={tableHeadingsArray}
        advancedSearchVisibility={advancedSearchVisibility}
        setAdvancedSearchVisibility={setAdvancedSearchVisibility}
        onSubmitAdvancedSearch={onSubmitAdvancedSearch}
        onResetAdvancedSearch={handleCloseButton}
        searchValue={searchTerm}
        handleSearchValue={handleSearchValue}
        openAdvancedSearch={agencyTableRowsMapper}
        tableTitle={t('relationship.agencies.agencies.agencies')}
        placeHolder={t('relationships.sellergroup.search')}
        addNewLink={{ link: '' }}
        order={order}
        orderBy={orderBy}
        isDataPagination={pagination}
        handleRequestSort={handleRequestSort}
        total={totalCount}
        err
        selectArray={selectArray}
        InputsComponent={(props: InputsComponentProps) => (
          <AdvancedSearchInputs
            {...{ formMethods: props.formMethods, advancedSearchInputParams }}
          />
        )}
      />
    </>
  );
}

export default AgentsList;
