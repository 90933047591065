import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import CustomTable from '../../components/shared/CustomTable';
import {
  AddNewLink,
  AdvancedSearchFieldType,
  AdvancedSearchParam,
  Cell,
  CellType,
  InputsComponentProps,
  NewLinkState,
  Order,
} from '../../types/customTable';
import {
  AdvancedSearchPayloadSellerGroup,
  SellerGroupInfo,
  initialSellerGroupSearchValues,
} from '../../types/sellerGroup';
import AdvancedSearchInputs from '../../components/shared/CustomTableSearchFields';
import { getSearchString, isNumber } from '../../utils';
import { linkColor } from '../../Theme/colorsVariables';
import { useSellerGroupAll } from '../../services/sellerGroupServices';
import { EntityType, Status } from '../../types/query-params';
import {
  QuickTableFilter,
  StatusSelectData,
} from '../../components/shared/CustomTableFilters/interface';
import { Grid } from '@mui/material';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import AuthService from '../../services/authService';
import { AddressState } from '../../types';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';
import { Link } from 'react-router-dom';
import { useAgentListSmall } from '../../services/agentService';
import { normalizeZip } from '../../utils/normalizeZip';
import { UserContext } from '../../context/User';
import { ConfigurationService } from '../../services/configurationService';
import { AppPhoneInputViewOnly } from '../../components/shared/AppPhoneFieldViewOnly';

const SEARCH_KEY = 'sellerGroupList.search';

function SellerGroupList() {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const [err, setErr] = useState<boolean>(false);
  const isViewSellerPermission = usePermissions(PermissionKeys.ViewSellers);
  const isReadOrganizationPermission = usePermissions(PermissionKeys.ReadOrganization);
  const isCreateOrganizationPermission = usePermissions(PermissionKeys.CreateOrganization);
  const isAddSellerGroupPermission = usePermissions(PermissionKeys.AddSellers); // To be replaced with Add Seller Group

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    agent,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    setAgent,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const [sellerGroupRows, setSellerGroupTableRows] = useState<Cell[][]>([]);
  const { data: legalEntityTypes } = ConfigurationService.useLegalEntityTypes();
  const { data: agentListForFilter } = useAgentListSmall();

  const fetchingId = useMemo(() => {
    return !user?.isProvider ? Number(user?.orgId) : 0;
  }, [user]);

  const agentOptions = useMemo<StatusSelectData[]>(
    () => [
      { value: t('status.any'), text: t('status.any') },
      ...(agentListForFilter?.map((item) => ({
        value: item.id.toString(),
        text: item.name,
      })) || []),
    ],
    [agentListForFilter, t],
  );

  const legalOrganizationOptions = useMemo(
    () =>
      legalEntityTypes?.map((item) => {
        return { value: item.legalEntityTypeId, text: item.legalEntityTypeDisplayName };
      }),
    [legalEntityTypes],
  );

  const { data: statesResponse } = AuthService.useStatesQuery();
  const states = useMemo<AddressState[]>(() => statesResponse || [], [statesResponse]);
  const stateDictionary = useMemo<Record<number, AddressState>>(() => {
    const obj = statesResponse?.reduce((o, key) => ({ ...o, [key.addressStateId]: key }), {});
    return obj || {};
  }, [statesResponse]);
  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Seller Group': 'sellerGroupName' },
      { 'Seller Group ID': 'customerOrgCode' },
      { Phone: 'phone' },
      { ZIP: 'PhysicalZipCode' },
      { State: 'PhysicalState' },
      { City: 'PhysicalCity' },
      // { Agency: 'AgencyName' },
      { Sellers: 'sellerCount' },
      // { Status: 'status' },
    ] as Record<string, string>[];
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState<boolean>(true);
  const [dataAdvancedSearch, setDataAdvancedSearch] = useState<AdvancedSearchPayloadSellerGroup>();
  const [advancedSearchVisibility, setAdvancedSearchVisibility] = useState(false);

  const [goError] = useState(false);
  const valueRef = useRef<HTMLInputElement>(null);

  const [addNewLink, setAddNewLink] = useState<AddNewLink>({
    link: '',
    state: NewLinkState.disabled,
  });

  useEffect(() => {
    if (isAddSellerGroupPermission && isCreateOrganizationPermission) {
      setAddNewLink({
        link: '/dashboard/SellerGroup/Add',
        state: NewLinkState.active,
      });
    }
  }, [isAddSellerGroupPermission, isCreateOrganizationPermission]);

  const {
    data: sellerGroups,
    isLoading: isDataLoading,
    refetch: refetchSellerGroups,
  } = useSellerGroupAll({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy?.split(' ').join(''),
    sortDirection: order,
    status: status?.label === Status.Any ? Status.Default : (status?.label as Status),
    name: !advancedSearchVisibility ? getSearchString(searchTerm) : dataAdvancedSearch?.name,
    phone: !advancedSearchVisibility
      ? isNumber(searchTerm.replaceAll('-', ''))
        ? searchTerm.replaceAll('-', '')
        : ''
      : dataAdvancedSearch?.phone,
    agencyId: agent?.value === EntityType.Any ? null : Number(agent?.value),
    advancedSearch: dataAdvancedSearch,
    isEnabled: isReadOrganizationPermission && isViewSellerPermission,
    shouldMatchAllCriteria: advancedSearchVisibility,
  });

  const { isLoading: isPreSellerGroupsLoading } = useDebouncedQuery(
    refetchSellerGroups,
    isReadOrganizationPermission && isViewSellerPermission,
  );

  const advancedSearchInputParams: AdvancedSearchParam[] = useMemo(
    () => [
      {
        label: `${t('organization.fieldLabel.legalName')}`,
        name: 'name',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.fein')}`,
        name: 'feinS',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.doingBusinessAs')}`,
        name: 'doingBusinessAs',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.legalOrganization')}`,
        name: 'organizationType',
        type: AdvancedSearchFieldType.Select,
        selectTypeParams: legalOrganizationOptions,
      },
      {
        label: `${t('organization.fieldLabel.phone')}`,
        name: 'phone',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.fax')}`,
        name: 'fax',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicaladdress1')}`,
        name: 'physicalAddress1',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicaladdress2')}`,
        name: 'physicalAddress2',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicalzipcode')}`,
        name: 'physicalZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalcity')}`,
        name: 'physicalCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalstate')}`,
        name: 'physicalState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
      {
        label: `${t('relationship.sellergroup.table.mailingaddress1')}`,
        name: 'mailingAddress1',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.mailingaddress2')}`,
        name: 'mailingAddress2',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.mailingzipcode')}`,
        name: 'mailingZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingcity')}`,
        name: 'mailingCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingstate')}`,
        name: 'mailingState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
    ],
    [t, legalOrganizationOptions, states],
  );

  const rowsMapper = useCallback(
    (data: SellerGroupInfo[]) => {
      const mappedCells = data.map((item) => [
        {
          data: item.sellerGroupName || '',
          type: CellType.Link,
          linkTo: `/dashboard/SellerGroup/${item.sellerGroupId}`,
          styles: { color: linkColor },
        },
        {
          data: item.customerOrgCode,
          type: CellType.Info,
        },
        {
          data: (
            <AppPhoneInputViewOnly
              phone={
                (item.phoneNumberCountryCode?.replace('+', '') || '') + (item.phoneNumber || '')
              }
            />
          ),
          type: CellType.Info,
        },
        {
          data: normalizeZip(item.zipCode) || '',
          type: CellType.Info,
        },
        {
          data: stateDictionary[item.stateId]?.stateName || '',
          type: CellType.Info,
        },
        {
          data: item.city || '',
          type: CellType.Info,
        },
        {
          data: (
            <Link
              style={{ color: linkColor }}
              to={
                item?.sellerCount > 0
                  ? `/dashboard/Seller?sellerGroupId=${item?.sellerGroupId}`
                  : ''
              }
              onClick={() => {
                localStorage.removeItem('sellerList.search');
              }}
            >{`${item.sellerCount} Sellers`}</Link>
          ),
          type: CellType.Action,
          linkTo:
            item?.sellerCount > 0 ? `/dashboard/Seller?sellerGroupId=${item?.sellerGroupId}` : '',
        },
      ]);
      setSellerGroupTableRows(mappedCells);
    },
    [stateDictionary],
  );

  const handleFilterAgent = useCallback(
    (agentFilter: { label: string; value: string }) => {
      setAgent(agentFilter);
      setPage(0);
    },
    [setPage, setAgent],
  );
  // Will be used in future. Currently not using it so that we can not test on this
  const selectArray: QuickTableFilter[] = useMemo(
    () => [
      {
        label: 'Agent',
        value: agent,
        handleFilter: handleFilterAgent,
        filterOptions: agentOptions,
        type: 'single',
        disabled: !!fetchingId,
      },
    ],
    [agent, agentOptions, handleFilterAgent, fetchingId],
  );

  useEffect(() => {
    if (!sellerGroups) {
      return;
    } else {
      if (!advancedSearchVisibility) {
        setDataAdvancedSearch({
          name: '',
          fein: '',
          organizationType: '',
          doingBusinessAs: '',
          physicalAddress1: '',
          physicalAddress2: '',
          fax: '',
          phone: '',
          physicalCity: '',
          physicalStateId: undefined,
          physicalZipCode: '',
          mailingAddress1: '',
          mailingAddress2: '',
          mailingCity: '',
          mailingStateId: undefined,
          mailingZipCode: '',
          status: '',
          agencyId: 0,
        });
      } else {
        setSearchTerm('');
        setStatus({ label: Status.Any, value: Status.Any });
      }
      rowsMapper(sellerGroups.data);
      setTotalCount(sellerGroups.totalCount);
    }
  }, [advancedSearchVisibility, sellerGroups, rowsMapper, setSearchTerm, setStatus]);

  //Grid Events
  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setPage, setRowsPerPage],
  );

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
      setPage(0);
    },
    [setOrder, setOrderBy, setPage],
  );

  const onSubmitAdvancedSearch = useCallback(
    (data: any) => {
      let flag = 0;
      Object.keys(data).forEach((item: any) => {
        if (data[item] !== '') {
          setErr(false);
          flag = 1;
        }
      });
      if (data?.physicalLocationState?.label) {
        setErr(false);
        flag = 1;
      }
      if (data?.mailingState?.label) {
        setErr(false);
        flag = 1;
      }
      if (flag === 0) {
        rowsMapper([]);
        setErr(true);
      } else {
        const dataToPost: AdvancedSearchPayloadSellerGroup = {
          name: data.name,
          phone: data.phone === undefined ? '' : data.phone.replaceAll('-', ''),
          physicalZipCode: data.physicalZipCode,
          physicalStateId:
            data.physicalState?.label === 'Any' ? '' : data.physicalState?.value?.addressStateId,
          physicalAddress1: data.physicalAddress1,
          physicalAddress2: data.physicalAddress2,
          physicalCity: data.physicalCity,
          mailingAddress1: data.mailingAddress1,
          mailingAddress2: data.mailingAddress2,
          mailingCity: data.mailingCity,
          mailingStateId:
            data.mailingState?.label === 'Any' ? '' : data.mailingState?.value?.addressStateId,
          mailingZipCode: data.mailingZipCode,
          fein: data.feinS === undefined ? '' : data.feinS.replaceAll('-', ''),
          organizationType:
            data.organizationType?.label === 'Any' ? '' : data.organizationType?.label,
          doingBusinessAs: data.doingBusinessAs,
          agencyId: data.agency,
          fax: data.fax === undefined ? '' : data.fax.replaceAll('-', ''),
          status: '',
        };
        setDataAdvancedSearch(dataToPost);
      }
      setPage(0);
    },
    [rowsMapper, setPage],
  );

  const handleCloseButton = useCallback(() => {
    setPage(0);
    onSubmitAdvancedSearch(initialSellerGroupSearchValues);
  }, [onSubmitAdvancedSearch, setPage]);

  useEffect(() => {
    if (fetchingId) {
      const selectedAgent = agentListForFilter?.find((item) => item.id === Number(fetchingId));
      if (selectedAgent) {
        setAgent({ label: selectedAgent?.name, value: selectedAgent?.id });
      }
    }
  }, [fetchingId, setAgent, agentListForFilter]);

  return (
    <>
      {isReadOrganizationPermission && isViewSellerPermission ? (
        <CustomTable
          isDataLoading={isDataLoading || isPreSellerGroupsLoading}
          order={order}
          orderBy={orderBy}
          handleRequestSort={onHandleSetOrder}
          goError={goError}
          page={page}
          rowsPerPage={rowsPerPage}
          data={sellerGroupRows}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          valueRef={valueRef}
          setPage={setPage}
          tableHeadingsArray={tableHeadingsArray}
          advancedSearchVisibility={advancedSearchVisibility}
          setAdvancedSearchVisibility={setAdvancedSearchVisibility}
          onSubmitAdvancedSearch={onSubmitAdvancedSearch}
          onResetAdvancedSearch={handleCloseButton}
          openAdvancedSearch={rowsMapper}
          searchValue={searchTerm}
          handleSearchValue={handleSearchValue}
          selectArray={selectArray}
          setPagination={setPagination}
          isDataPagination={pagination}
          addNewLink={addNewLink}
          tableTitle="Seller Groups"
          placeHolder={t('relationships.sellergroup.search')}
          total={totalCount}
          err={err}
          InputsComponent={(props: InputsComponentProps) => (
            <AdvancedSearchInputs
              {...{ formMethods: props.formMethods, advancedSearchInputParams }}
            />
          )}
        />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default React.memo(SellerGroupList);
