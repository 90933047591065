import { FormControl, Grid, InputLabel, InputProps, OutlinedInput } from '@mui/material';
import React, { forwardRef, useCallback } from 'react';
import { FC } from 'react';
import { IMaskInput } from 'react-imask';
import {
  MainTextColor,
  Platinum,
  RedOrange,
  SecondaryTextColor,
  White,
} from '../../../Theme/colorsVariables';
import { CountryData, PhoneInputProps } from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import { AppTextField } from '../AppTextField';
import styled from 'styled-components';
import { StyledReactPhoneInput } from './styles';
import { allowNumbersOnly, getCommonErrorMessages } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

export const StyledFormControl = styled(FormControl)`
  .MuiInputBase-root {
    background: ${(props) => (props.disabled ? Platinum : White)};
    border: none;
    border-radius: 0;

    input {
      font-size: 16px;
      font-family: Gotham-Medium;
    }
    input[disabled] {
      background: ${Platinum};
      -webkit-text-fill-color: ${MainTextColor} !important;
    }
    &.Mui-error {
      fieldset {
        border: 1px solid ${RedOrange} !important;
      }
    }

    & fieldset {
      border: none;
      border-radius: unset;
      span {
        font-size: 22px;
      }
    }
  }
  .MuiFormLabel-root.Mui-error {
    color: ${RedOrange} !important;
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-focused {
    font-family: Gotham-Book;
    font-size: 16px;
    color: ${SecondaryTextColor};
  }
  .MuiInputLabel-shrink,
  .MuiInputLabel-shrink.Mui-focused {
    font-size: 20px !important;
    top: -2px;
  }
  @media only screen and (max-width: 1500px) {
    .MuiInputBase-root fieldset span {
      font-size: 18px;
    }

    .MuiInputBase-root {
      input,
      textarea {
        font-size: 12px;
      }
    }

    .MuiFormLabel-root,
    .MuiFormLabel-root.Mui-focused {
      font-size: 12px;
    }
    .MuiInputLabel-shrink,
    .MuiInputLabel-shrink.Mui-focused {
      font-size: 16px !important;
      top: 0px;
      left: -2px;
    }
    .MuiFormHelperText-root {
      margin-top: 0;
      font-size: 10px;
    }
    .MuiAutocomplete-endAdornment {
      top: calc(50% - 14px);
    }
  }
`;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

interface FaxFieldProps extends InputProps {
  label: string;
  errorMessage?: string;
}

export const AppFaxField: FC<FaxFieldProps> = forwardRef((props, ref) => {
  const { errorMessage, ...otherProps } = props;
  return (
    <StyledFormControl
      variant="outlined"
      fullWidth
      error={!!errorMessage}
      required={props.required}
    >
      <InputLabel htmlFor="formatted-text-mask-input">
        {errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      </InputLabel>
      <OutlinedInput
        {...otherProps}
        ref={ref}
        inputComponent={TextMaskCustom as any}
        label={errorMessage ? `${props.label} - ${errorMessage}` : props.label}
      />
    </StyledFormControl>
  );
});

export type PhoneString = { isPhoneValid: string };

export interface AppPhoneField2Props extends Omit<PhoneInputProps, 'component'> {
  required?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
}

export const getPhonePayload = (phone: { number: string; countryCode: string; ext: string }) => {
  return {
    phoneNumber: phone.number.replace(phone.countryCode, '') || null,
    phoneNumberCountryCode: phone.countryCode || null,
    phoneNumberExt: phone.ext || null,
  };
};

export const AppPhoneField: FC<AppPhoneField2Props> = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<{
    phone: { number: string; countryCode: string; ext: string; isValid: boolean };
  }>();
  const {
    phone: { isValid },
  } = watch();

  const { required, disabled, size } = props;

  const isPhoneValid = useCallback(
    (value: string, country: CountryData, formattedValue: string) => {
      const { format, dialCode } = country;
      const countOfNumbersInFormat = (format.match(new RegExp(/\./, 'g')) || []).length;

      // Telephone numbers are standardised by ITU-T in their recommendation E.164.
      // In the recommendation they say telephone numbers should not exceed 15 digits.
      if (countOfNumbersInFormat > 15) {
        if (value.length === 15) {
          return true;
        }
        return false;
      }
      if (
        format?.length === formattedValue?.length &&
        (value.startsWith(dialCode) || dialCode.startsWith(value))
      ) {
        return true;
      } else {
        return false;
      }
    },
    [],
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Controller
          control={control}
          name={'phone.number'}
          render={({ field }) => (
            <StyledReactPhoneInput
              country={'us'}
              component={AppTextField}
              disabled={!!disabled}
              inputProps={{
                required,
                disabled,
                size,
                error: !!errors.phone?.number?.message ? true : !isValid,
                errorMessage: !!errors.phone?.number?.message
                  ? errors.phone?.number?.message
                  : !isValid && getCommonErrorMessages('phone'),
                name: 'phone',
              }} // Props that will be passed to AppTextField
              inputStyle={{ height: '100%' }}
              {...field}
              onChange={(value, country: CountryData, e, formattedValue) => {
                const { dialCode } = country;

                if (!required && !value) {
                  if (setValue) setValue('phone.isValid', true, { shouldValidate: true });
                } else {
                  if (isPhoneValid(value, country, formattedValue)) {
                    if (setValue) setValue('phone.isValid', true, { shouldValidate: true });
                  } else {
                    if (setValue) setValue('phone.isValid', false, { shouldValidate: true });
                  }
                }
                setValue('phone.number', value);
                setValue('phone.countryCode', dialCode);
              }}
              onBlur={(e, data: CountryData) => {
                const { dialCode } = data;
                const formattedValue = e.target.value;
                const value = formattedValue.replaceAll(/\D/g, '');

                if (!required && !value) {
                  if (setValue) setValue('phone.isValid', true, { shouldValidate: true });
                } else {
                  if (isPhoneValid(value, data, formattedValue)) {
                    if (setValue) setValue('phone.isValid', true, { shouldValidate: true });
                  } else {
                    if (setValue) setValue('phone.isValid', false, { shouldValidate: true });
                  }
                }

                setValue('phone.number', value);
                setValue('phone.countryCode', value ? dialCode : '');
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          control={control}
          name="phone.ext"
          render={({ field }) => (
            <AppTextField
              {...field}
              label={t('security.users.userForm.ext')}
              inputProps={{
                maxLength: 6,
              }}
              name={'ext'}
              onKeyDown={allowNumbersOnly}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    </Grid>
  );
});
