import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { formatUtcToLocalTime } from '../../../utils/formatUtcToLocalTime';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { useToast } from '../../../hooks/useToast';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AppTooltip from '../../../components/shared/AppTooltip';
import { SpaceBetweenBox } from '../../../components/shared/FlexBox';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { AppTextField } from '../../../components/shared/AppTextField';
import { ActionIconWrapper, HoverContainerTableCell } from './styles';

export default function TenantSetupPage() {
  const apiRef = useGridApiRef();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isReadEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const isModifyEnabled = usePermissions(PermissionKeys.ModifyConfiguration);
  const {
    data: tenantSetupInfo,
    refetch,
    isFetching,
  } = ConfigurationService.useTenantConfiguration({
    enabled: isReadEnabled,
  });
  const { mutate: updateValue } = ConfigurationService.useUpdateTenantConfiguration({
    onSuccess() {
      toast.success('Updated Successfully.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  const handleProcessRowUpdate = (newRow: any) => {
    updateValue({
      systemConfigureConstantId: newRow.id as number,
      constantValue: newRow.constantValue,
    });
    return newRow; // Return the updated row
  };

  const rows = useMemo(() => {
    return (
      tenantSetupInfo?.map((item) => ({
        ...item,
        createdOn: formatUtcToLocalTime(item.createdOn || ''),
        createdBy: item.createdBy?.name,
        updatedOn: formatUtcToLocalTime(item.updatedOn || ''),
        updatedBy: item.updatedBy?.name,
        id: item.systemConfigureConstantId,
      })) || []
    );
  }, [tenantSetupInfo]);

  const columns: GridColDef[] = [
    { field: 'constantType', headerName: 'Constant Type' },
    { field: 'description', headerName: 'Description' },
    {
      field: 'constantValue',
      headerName: 'Constant Value',
      editable: isModifyEnabled,
      renderCell: (params) => {
        const apiRef1 = params.api; // Access the apiRef via params

        const handleEditClick = () => {
          apiRef1.startCellEditMode({ id: params.id, field: params.field });
        };
        return (
          <SpaceBetweenBox position={'relative'} height={'100%'}>
            {params.value}
            <HoverContainerTableCell className="edit-icon-container">
              <AppTooltip title="Edit" placement="right">
                <ActionIconWrapper
                  onClick={handleEditClick} // Start edit mode on click
                >
                  <EditIcon fontSize="small" />
                </ActionIconWrapper>
              </AppTooltip>
            </HoverContainerTableCell>
          </SpaceBetweenBox>
        );
      },
      renderEditCell: (params) => {
        const apiRef1 = params.api;

        const handleSaveClick = async () => {
          await apiRef1.setEditCellValue({
            id: params.id,
            field: params.field,
            value: params.value,
          });
          apiRef1.stopCellEditMode({ id: params.id, field: params.field });

          updateValue({
            systemConfigureConstantId: params.id as number,
            constantValue: params.value,
          });
        };

        const handleCancelClick = () => {
          apiRef1.stopCellEditMode({
            id: params.id,
            field: params.field,
            ignoreModifications: true,
          });
        };

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <AppTextField
              sx={{
                '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 'none !important',
                },
              }}
              value={params.value}
              onChange={(e) =>
                apiRef1.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: e.target.value,
                })
              }
            />
            <AppTooltip title="Save" placement="bottom-end">
              <ActionIconWrapper onClick={handleSaveClick}>
                <CheckIcon fontSize="small" />
              </ActionIconWrapper>
            </AppTooltip>
            <AppTooltip title="Cancel" placement="bottom-end">
              <ActionIconWrapper onClick={handleCancelClick}>
                <CloseIcon fontSize="small" />
              </ActionIconWrapper>
            </AppTooltip>
          </div>
        );
      },
    },
    { field: 'createdOn', headerName: 'Created On' },
    { field: 'createdBy', headerName: 'Created By' },
    { field: 'updatedOn', headerName: 'Updated By' },
    { field: 'updatedBy', headerName: 'Updated By' },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: [
          'constantType',
          'description',
          'constantValue',
          'createdOn',
          'createdBy',
          'updatedOn',
          'updatedBy',
        ],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, tenantSetupInfo]);

  useEffect(() => {
    if (!isReadEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isReadEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Tenant Setup" />
      </Box>

      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
        processRowUpdate={handleProcessRowUpdate}
        editMode="cell"
      />
    </>
  );
}
