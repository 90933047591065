import { object, array, number } from 'yup';
import { commonSelectSchema, trimmedString } from '.';
import { getCommonErrorMessages } from '../utils';
import { getCommonMaxLengthValue } from '../utils/inputMaxLength';

export const userValidationSchema = object().shape({
  firstName: trimmedString
    .max(
      getCommonMaxLengthValue('firstName'),
      getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('firstName'),
    )
    .required(getCommonErrorMessages('required')),
  lastName: trimmedString
    .max(
      getCommonMaxLengthValue('lastName'),
      getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('lastName'),
    )
    .required(getCommonErrorMessages('required')),
  email: trimmedString
    .required(getCommonErrorMessages('required'))
    .email(getCommonErrorMessages('email')),
  assosiatedWith: commonSelectSchema,
  orgName: commonSelectSchema,
  phone: object().shape({
    number: trimmedString,
    countryCode: trimmedString,
    ext: trimmedString,
  }),
  title: trimmedString
    .max(
      getCommonMaxLengthValue('title'),
      getCommonErrorMessages('maxLength') + getCommonMaxLengthValue('title'),
    )
    .nullable(),
  fakePermissionArrayForPermissionsValidation: array(number())
    .required(getCommonErrorMessages('required'))
    .min(1, getCommonErrorMessages('permission'))
    .default([]),
});

export const userProfileValidationSchema = object().shape({
  firstName: trimmedString.required(getCommonErrorMessages('required')),
  lastName: trimmedString.required(getCommonErrorMessages('required')),
  email: trimmedString
    .required(getCommonErrorMessages('required'))
    .email(getCommonErrorMessages('email')),
  phone: object().shape({
    number: trimmedString,
    countryCode: trimmedString,
    ext: trimmedString,
  }),
  ext: trimmedString.nullable(),
  title: trimmedString.max(getCommonMaxLengthValue('title')).nullable(),
});
