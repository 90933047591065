import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';

import { AppActionButton } from '../../shared/AppActionButton';
import { ModalButtonsBox } from '../Modal/styles';
import { ModalMessageText } from '../CancelModal/styles';

interface ContactStatusProps {
  popUp: boolean;
  handleYes: () => void;
  handleModal: () => void;
  statusChangeMessage: any;
}

export default function ContactStatusModal(props: ContactStatusProps) {
  const { t } = useTranslation();
  const { popUp, handleYes, handleModal, statusChangeMessage } = props;
  return (
    <CustomModal
      open={popUp}
      containerWidth="400px"
      modalTitle={t('security.roles.roleForm.role.warning')}
    >
      <>
        <ModalMessageText
          dangerouslySetInnerHTML={{
            __html: statusChangeMessage,
          }}
        />
        <ModalButtonsBox>
          <AppActionButton variant="outlined" onClick={handleYes}>
            {t('user.actions.confirm')}
          </AppActionButton>
          <AppActionButton variant="outlined" onClick={handleModal}>
            {t('user.actions.cancel')}
          </AppActionButton>
        </ModalButtonsBox>
      </>
    </CustomModal>
  );
}
