import React, { useState, ChangeEvent, useRef, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../components/shared/CustomTable';
import ProductService from '../../services/productService';
import AuthService from '../../services/authService';
import { ProductObj, ProductsData, AddressState } from '../../types';
import { AddNewLink, Cell, CellType, NewLinkState } from '../../types/customTable';
import { Order, Status } from '../../types/query-params';

import useDebounce from '../../hooks/useDebounce';
import {
  QuickTableFilter,
  StatusSelectData,
} from '../../components/shared/CustomTableFilters/interface';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import useTrim from '../../hooks/useTrim';
import { linkColor } from '../../Theme/colorsVariables';
import { getSearchString } from '../../utils';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';

const SEARCH_KEY = 'productLineList.search';

export default function ProductLineList() {
  const isAddProductPermission = usePermissions(PermissionKeys.AddProducts);
  const { t } = useTranslation();

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    availableInStates,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    setAvailableInStates,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const [productRows, setProductRows] = useState<Cell[][]>([]);
  const [advancedSearchVisibility, setAdvancedSearchVisibility] = useState(false);
  const [goError] = useState(false);
  const valueRef = useRef<HTMLInputElement>(null);
  const tableHeadingsArray = useMemo(() => {
    return [
      { Name: 'productName' },
      { 'Available in State(s)': 'statesCount' },
      { Status: 'status' },
    ] as Record<string, string>[];
  }, []);
  const debouncedSearch: string = useDebounce(searchTerm, 300);
  const trimValue = useTrim(searchTerm);
  const selectStatesId = availableInStates.map((item) => {
    return item.stateCode === 'N/A' ? 0 : item.addressStateId;
  });

  const {
    data: productList,
    isLoading,
    refetch: refetchProducts,
  } = ProductService.useProductsListAll({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy,
    sortDirection: order,
    name: getSearchString(debouncedSearch),
    addressStateIds: selectStatesId,
    productName: getSearchString(debouncedSearch),
    status: status?.label === 'Any' ? '' : status?.label,
  });

  const { isLoading: isPreProductsLoading } = useDebouncedQuery(refetchProducts, true);

  const { data: statesList } = AuthService.useStatesQuery();
  const statesArray = useMemo(() => (statesList ? statesList : []), [statesList]);
  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);
  const [addNewLink, setAddNewLink] = useState<AddNewLink>({
    link: '',
    state: NewLinkState.active || NewLinkState.disabled,
  });
  const [selectArray, setSelectArray] = useState<QuickTableFilter<AddressState[]>[]>();
  const [totalCount, setTotalCount] = useState(0);

  const statesMapping = (item: ProductObj): string => {
    if (item?.productAddressStates.length) {
      const { productAddressStates: addressStates } = item;
      if (addressStates.length > 2) {
        return `${addressStates[0]?.addressState?.stateCode}, ${
          addressStates[1]?.addressState?.stateCode
        } and ${addressStates.length - 2} more`;
      }
      if (addressStates.length > 1) {
        return `${addressStates[0]?.addressState?.stateCode}, ${addressStates[1]?.addressState?.stateCode}`;
      }
      return `${addressStates[0]?.addressState?.stateCode}`;
    }
    return 'N/A';
  };

  const productRowsMapper = useCallback((data: ProductsData[]): void => {
    const mappedCells = data.map((item) => [
      {
        data: item.productName,
        type: CellType.Link,
        linkTo: `/dashboard/ProductLine/${item.productId}`,
        styles: { color: linkColor },
      },
      {
        data: statesMapping(item),
        type: CellType.Info,
      },
      {
        data: item.status ? 'Active' : 'Inactive',
        type: CellType.StatusFilter,
        defaultStatus: 'Active',
      },
    ]);
    setProductRows(mappedCells);
  }, []);

  const handleStatusChange = useCallback(
    (value: { label: Status; value: Status }) => {
      setStatus(value);
      setPage(0);
    },
    [setPage, setStatus],
  );

  const menuItems: StatusSelectData[] = useMemo(
    () => [
      { value: Status.All, text: t('status.any') },
      { value: Status.Active, text: t('status.active') },
      { value: Status.Inactive, text: t('status.inactive') },
    ],
    [t],
  );

  useEffect(() => {
    const selectArrayProduct: QuickTableFilter[] = [
      {
        label: '',
        value: Status.All,
        handleFilter: () => undefined, // paste shange selected
        filterOptions: [],
        type: 'multiAutocomplete',
        renderSelectChip: undefined,
        items: statesArray,
        selectedItems: availableInStates,
        changeSelectedItems: (value) => setAvailableInStates(value),
      },
      {
        label: t('security.roles.rolesList.status'),
        value: status,
        handleFilter: handleStatusChange,
        filterOptions: menuItems,
        type: 'single',
      },
    ];
    setSelectArray(selectArrayProduct);
  }, [
    statesArray,
    menuItems,
    t,
    handleStatusChange,
    status,
    availableInStates,
    setAvailableInStates,
  ]);

  useEffect(() => {
    if (availableInStates.length) {
      productRowsMapper([]);
      const selectedAddressStates = availableInStates.map((state) => state.addressStateId);
      const filteredProducts = productList?.data.filter((product) =>
        product.productAddressStates.some(({ addressStateId }) =>
          selectedAddressStates.some((item) => item === addressStateId),
        ),
      );

      productRowsMapper(filteredProducts || []);
    } else if (productList) {
      productRowsMapper(productList.data);
    }
  }, [productRowsMapper, productList, availableInStates]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setSearchTerm, setPage],
  );

  useEffect(() => {
    if (!productList) {
      return;
    } else {
      if (!advancedSearchVisibility) {
        productRowsMapper(productList.data);
      }
      setTotalCount(productList.totalCount);
    }
  }, [advancedSearchVisibility, productRowsMapper, productList]);

  const onCloseAdvancedSearch = useCallback(() => {
    setAdvancedSearchVisibility(!advancedSearchVisibility);
    if (productList) {
      productRowsMapper(productList.data);
    }
  }, [advancedSearchVisibility, productList, productRowsMapper]);

  const handleCloseButton = useCallback(() => {
    productRowsMapper([]);
  }, [productRowsMapper]);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      if (!productRows) return;

      setOrder(newOrder);

      setOrderBy(newOrderBy);
    },
    [productRows, setOrder, setOrderBy],
  );

  useEffect(() => {
    if (isAddProductPermission) {
      setAddNewLink({ link: '/dashboard/ProductLine/Add', state: NewLinkState.active });
    } else {
      setAddNewLink({ link: '', state: NewLinkState.disabled });
    }
  }, [isAddProductPermission]);

  return (
    <CustomTable
      setPagination={setPagination}
      isDataLoading={isLoading || isPreProductsLoading}
      page={page}
      rowsPerPage={rowsPerPage}
      data={productRows}
      valueRef={valueRef}
      goError={goError}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
      setPage={setPage}
      tableHeadingsArray={tableHeadingsArray}
      advancedSearchVisibility={advancedSearchVisibility}
      setAdvancedSearchVisibility={onCloseAdvancedSearch}
      onSubmitAdvancedSearch={() => {}}
      onResetAdvancedSearch={handleCloseButton}
      openAdvancedSearch={productRowsMapper}
      searchValue={trimValue}
      handleSearchValue={handleSearchValue}
      selectArray={selectArray}
      tableTitle="Product Lines"
      placeHolder={t('relationships.sellergroup.search')}
      addNewLink={addNewLink}
      isDataPagination={pagination}
      isAdvanceSearch={false}
      order={order}
      orderBy={orderBy}
      total={totalCount}
      handleRequestSort={onHandleSetOrder}
      InputsComponent={function (): JSX.Element {
        return <></>;
      }}
    />
  );
}
