import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { ChangeEvent, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { AdvancedSearchInputProps } from '../../../types/customTable';
import {
  allowNumbersOnly,
  getCommonErrorMessages,
  normalizePhone,
  pastingCheck,
} from '../../../utils';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';
import AppAutocompleteSingleSelect from '../AppAutocompleteSingleSelect';
import StatesMultipleSelect from '../StatesMultipleSelectChips';
import { normalizeFein } from '../../../utils/normalizeFein';
import { AppTextFieldDelayedHelperText } from '../AppTextFieldDelayedHelperText';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { AppPhoneField } from '../AppPhoneField';
import { ProviderFetchingQuery } from '../../../services/sellerServices';

function AdvancedSearchInputs({
  formMethods,
  advancedSearchInputParams,
  children,
}: AdvancedSearchInputProps) {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
  } = formMethods;
  const numericValues: string[] = [
    'physicalLocationZip',
    'phone',
    'mailingZip',
    'fax',
    'mailingZipCode',
    'physicalZipCode',
    'feinS',
  ];
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { data: providerInfo } = ProviderFetchingQuery();

  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);

  useEffect(() => {
    if (isReadProviderUserPermission && !isReadAgentUserPermission) {
      setValue('associatedWithId', { label: 'Provider', value: 'Provider' });
      setValue('entityId', { label: providerInfo?.legalName, value: providerInfo?.legalName });
    }
    if (!isReadProviderUserPermission && isReadAgentUserPermission) {
      setValue('associatedWithId', { label: 'Agent', value: 'Agent' });
    }
    if (isReadProviderUserPermission && isReadAgentUserPermission) {
      setValue('associatedWithId', { label: 'Any', value: undefined });
    }
  }, [isReadProviderUserPermission, isReadAgentUserPermission, setValue, providerInfo?.legalName]);

  return (
    <>
      {children}
      <Grid container spacing={isSmall ? 1 : 2} mt={1}>
        {advancedSearchInputParams.map((row) => {
          if (row.type === 'select' || row.type === 'stateSelect') {
            return (
              <Grid item xs={4} key={row.label}>
                <AppAutocompleteSingleSelect
                  control={control}
                  name={row.name}
                  label={row.label}
                  size="small"
                  options={
                    row.selectTypeParams?.map((option) => ({
                      label: option.text,
                      value: option.value,
                    })) || []
                  }
                  onChange={(data) => {
                    if (row.name === 'associatedWithId' && row.callback) {
                      if (data && data.value === '1') {
                        setValue('entityId', {
                          label: providerInfo?.legalName,
                          value: providerInfo?.legalName,
                        });
                        row.callback(data);
                      } else {
                        setValue('entityId', {
                          label: undefined,
                          value: undefined,
                        });
                        row.callback(data);
                      }
                    }
                  }}
                  disabled={row.disabled}
                />
              </Grid>
            );
          } else if (row.type === 'selectMulti') {
            return (
              <Grid item xs={4} key={row.label}>
                <Controller
                  render={() => (
                    <StatesMultipleSelect
                      disabled={false}
                      check={true}
                      selectedData={row.selectedValues}
                      originalData={row.multiSelectArray}
                      handleChangeSelected={(data) => {
                        if (row.callback) {
                          row.callback(data);
                        }
                      }}
                    />
                  )}
                  name={row.name}
                  control={control}
                  defaultValue=""
                />
              </Grid>
            );
          } else if (row.type === 'emptySpace') {
            return <Grid item xs={4} key={row.label}></Grid>;
          } else if (row.type === 'phone') {
            return (
              <Grid item xs={4} key={row.label}>
                <Controller
                  key={row.name}
                  render={({ field }) => {
                    return <AppPhoneField {...field} required={false} size={'small'} />;
                  }}
                  name={row.name}
                  control={control}
                  defaultValue=""
                />
              </Grid>
            );
          } else {
            return (
              <Grid item xs={4} key={row.label}>
                <Controller
                  key={row.name}
                  render={({ field }) => {
                    return (
                      <AppTextFieldDelayedHelperText
                        data-cy={'data-cy-advanced-search-field'}
                        size="small"
                        label={`${row.label}`}
                        placeholder={row.label}
                        {...field}
                        inputProps={{
                          minLength: 3,
                          maxLength: getCommonMaxLengthValue(field.name),
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value !== '' && e.target.value.length < 3) {
                            setError(field.name, {
                              type: 'custom',
                              message: getCommonErrorMessages('helperTextMessage'),
                            });
                          } else {
                            clearErrors(field.name);
                          }

                          if (field.name === 'phone' || field.name === 'fax') {
                            setValue(field.name, normalizePhone(e.target.value));
                            setValue(field.name, pastingCheck(getValues(field.name)));
                          } else if (field.name === 'feinS') {
                            setValue(field.name, normalizeFein(e.target.value));
                          } else {
                            setValue(field.name, e.target.value);
                          }
                        }}
                        onKeyPress={
                          numericValues.includes(field.name) ? allowNumbersOnly : undefined
                        }
                        helperText={errors?.[field.name]?.message && t('appTextField.helperText')}
                      />
                    );
                  }}
                  name={row.name}
                  control={control}
                  defaultValue=""
                />
              </Grid>
            );
          }
        })}
      </Grid>
    </>
  );
}

export default AdvancedSearchInputs;
