import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { AppTextField } from '../shared/AppTextField';
import { formatUtcToLocalTime } from '../../utils/formatUtcToLocalTime';
import { SectionHeadings } from '../Forms/CreateOrganizationForm/styles';

export interface IModificationLog {
  createdOn: string | null | undefined;
  createdBy: { id: number; name: string } | null | undefined;
  updatedOn: string | null | undefined;
  updatedBy: { id: number; name: string } | null | undefined;
}

export const ModificationLog = ({
  createdOn,
  createdBy,
  updatedBy,
  updatedOn,
}: IModificationLog) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <Grid container spacing={isSmall ? 1 : 2} my={1}>
      <Grid item xs={12}>
        <SectionHeadings>Modification Log</SectionHeadings>
      </Grid>
      <Grid item xs={6}>
        <AppTextField value={createdBy?.name || ''} label={'Created By'} disabled />
      </Grid>
      <Grid item xs={6}>
        <AppTextField value={formatUtcToLocalTime(createdOn || '')} label={'Created On'} disabled />
      </Grid>
      <Grid item xs={6}>
        <AppTextField value={updatedBy?.name || ''} label={'Updated By'} disabled />
      </Grid>
      <Grid item xs={6}>
        <AppTextField value={formatUtcToLocalTime(updatedOn || '')} label={'Updated On'} disabled />
      </Grid>
    </Grid>
  );
};
