import React, {
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../components/shared/CustomTable';
import { useSellerAll } from '../../services/sellerServices';
import { SellerInfo, AddressState } from '../../types';
import {
  Cell,
  AdvancedSearchParam,
  AdvancedSearchFieldType,
  CellType,
  NewLinkState,
  AddNewLink,
  InputsComponentProps,
} from '../../types/customTable';
import { Order, Status } from '../../types/query-params';
import {
  QuickTableFilter,
  StatusSelectData,
} from '../../components/shared/CustomTableFilters/interface';
import AdvancedSearchInputs from '../../components/shared/CustomTableSearchFields';
import { AdvancedSearchPayloadData } from '../AgentsList/types';
import AuthService from '../../services/authService';
import useDebounce from '../../hooks/useDebounce';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { initialSellerSearchValues } from '../../data/defaultAdvancedSearchFormValues';
import { getSearchString, isNumber } from '../../utils';
import { useQuery } from '../../hooks/useQuery';
import { linkColor } from '../../Theme/colorsVariables';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';
import { useAllSellerGroups } from '../../services/sellerGroupServices';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAgentListSmall } from '../../services/agentService';
import { normalizeZip } from '../../utils/normalizeZip';
import { UserContext } from '../../context/User';
import { ConfigurationService } from '../../services/configurationService';
import { AppPhoneInputViewOnly } from '../../components/shared/AppPhoneFieldViewOnly';

const SEARCH_KEY = 'sellerList.search';

export const SellerList = ({ orgId }: { orgId?: number }) => {
  const isAddSellerPermission = usePermissions(PermissionKeys.CreateOrganization);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useContext(UserContext);
  const location = useLocation();
  const [sellerRows, setSellerRows] = useState<Cell[][]>([]);
  const agentId = useQuery('agentId');
  const sellerGroupId = useQuery('sellerGroupId');
  const { id: sellerGroupIdOnSellerGroupTab } = useParams();
  const [advancedSearchVisibility, setAdvancedSearchVisibility] = useState(false);
  const [err, setErr] = useState<boolean>(false);
  const { data: statesResponse } = AuthService.useStatesQuery();
  const states = useMemo<AddressState[]>(() => statesResponse || [], [statesResponse]);
  const [dataAdvancedSearch, setDataAdvancedSearch] = useState<AdvancedSearchPayloadData>();

  const isSellerGroupTab = useMemo(() => {
    return location.pathname.includes('/SellerGroup');
  }, [location.pathname]);

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    sellerGroup,
    agent,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    resetSearch,
    setSellerGroup,
    setAgent,
  } = useSaveSearchCriteria({
    searchKey: !isSellerGroupTab ? SEARCH_KEY : undefined,
  });

  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearch: string = useDebounce(searchTerm, 300);
  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);

  const fetchingId = useMemo(
    () => (!agentId ? (!user?.isProvider ? Number(user?.orgId) : 0) : Number(agentId)),
    [user, agentId],
  );

  const { data: sellerGroups } = useAllSellerGroups({
    enabled: !isSellerGroupTab,
  });

  const { data: agentListForFilter } = useAgentListSmall();

  const {
    data: sellers,
    isLoading: isSellersLoading,
    refetch: refetchSellers,
  } = useSellerAll({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy?.split(' ')?.join(''),
    sortDirection: order,
    agentId: fetchingId ? fetchingId.toString() : agent?.value?.toString(),
    sellerGroupIds: orgId
      ? [orgId]
      : sellerGroup.length
      ? sellerGroup.filter((item) => item?.label !== 'Any').map((item) => Number(item?.value))
      : null,
    status: status?.label === Status.Any ? Status.Default : (status?.label as Status),
    name: !advancedSearchVisibility ? getSearchString(debouncedSearch) : dataAdvancedSearch?.name,
    advancedSearch: advancedSearchVisibility
      ? dataAdvancedSearch
      : { phone: isNumber(debouncedSearch) ? debouncedSearch : '' },
    customerOrgId: advancedSearchVisibility
      ? dataAdvancedSearch?.sellerId
        ? +dataAdvancedSearch?.sellerId
        : null
      : isNumber(debouncedSearch)
      ? +debouncedSearch
      : null,
    advancedSearchVisibility: sellerGroupIdOnSellerGroupTab ? true : advancedSearchVisibility,
  });

  const { isLoading: isPreSellersLoading } = useDebouncedQuery(refetchSellers, true);

  const { t } = useTranslation();

  const addSellerButtonState = useMemo<AddNewLink>(() => {
    if (isAddSellerPermission && !isSellerGroupTab) {
      return { link: '/dashboard/Seller/Add', state: NewLinkState.active };
    } else {
      return { link: '', state: NewLinkState.disabled };
    }
  }, [isAddSellerPermission, isSellerGroupTab]);

  const sellerGroupOptions = useMemo<StatusSelectData[]>(
    () => [
      { value: t('status.any'), text: t('status.any') },
      ...(sellerGroups?.map((item) => ({
        value: item.id.toString(),
        text: item.name,
      })) || []),
    ],
    [sellerGroups, t],
  );

  const agentOptions = useMemo<StatusSelectData[]>(
    () => [
      { value: t('status.any'), text: t('status.any') },
      ...(agentListForFilter?.map((item) => ({
        value: item.id.toString(),
        text: item.name,
      })) || []),
    ],
    [agentListForFilter, t],
  );

  const stateDictionary = useMemo<Record<number, AddressState>>(() => {
    const obj = statesResponse?.reduce((o, key) => ({ ...o, [key.addressStateId]: key }), {});
    return obj || {};
  }, [statesResponse]);

  const sellerRowsMapper = useCallback(
    (data: SellerInfo[]) => {
      const getSellerGroupColumn = (name: string, id: number): Cell[] => {
        if (!isSellerGroupTab) {
          return [
            {
              data: name || '',
              type: CellType.Link,
              linkTo: `/dashboard/SellerGroup/${id}`,
              styles: { color: linkColor },
            },
          ];
        }
        return [];
      };
      const mappedCells = data.map((item) => [
        {
          data: (
            <Link
              to={`/dashboard/Seller/${item.sellerId}`}
              target={isSellerGroupTab ? '_blank' : '_self'}
              style={{ color: linkColor }}
              data-cy={'table-link'}
            >
              {item.sellerName || ''}
            </Link>
          ),
          type: CellType.Action,
        },
        {
          data: item.customerOrgId || '',
          type: CellType.Info,
        },
        {
          data: (
            <AppPhoneInputViewOnly
              phone={
                (item.phoneNumberCountryCode?.replace('+', '') || '') + (item.phoneNumber || '')
              }
            />
          ),
          type: CellType.Info,
        },
        {
          data: normalizeZip(item.zipCode) || '',
          type: CellType.Info,
        },
        {
          data: stateDictionary[item.stateId]?.stateName || '',
          type: CellType.Info,
        },
        {
          data: item.city || '',
          type: CellType.Info,
        },
        ...getSellerGroupColumn(item.sellerGroupName, item.sellerGroupId),
      ]);
      setSellerRows(mappedCells);
    },
    [isSellerGroupTab, stateDictionary],
  );

  const handleFilterSellerGroup = useCallback(
    (sellerGroupFilter: { label: string; value: string }[]) => {
      if (
        sellerGroupFilter.length === 0 ||
        sellerGroupFilter[sellerGroupFilter.length - 1].label === 'Any'
      ) {
        setSellerGroup([{ label: 'Any', value: 'Any' }]);
        setPage(0);
        return;
      }
      setSellerGroup(sellerGroupFilter.filter((item) => item.label !== 'Any'));
      setPage(0);
    },
    [setPage, setSellerGroup],
  );
  const handleFilterAgent = useCallback(
    (agentFilter: { label: string; value: string }) => {
      if (agentId) {
        searchParams.delete('agentId');
        setSearchParams(searchParams);
      }
      setAgent(agentFilter);
      setPage(0);
    },
    [agentId, setAgent, setPage, searchParams, setSearchParams],
  );

  useEffect(() => {
    if (isSellerGroupTab) {
      setSellerGroup([{ label: '', value: sellerGroupIdOnSellerGroupTab || '' }]);
    }
  }, [isSellerGroupTab, sellerGroupIdOnSellerGroupTab, setSellerGroup]);

  const { data: legalEntityTypes } = ConfigurationService.useLegalEntityTypes();

  const legalOrganizationOptions = useMemo(
    () =>
      legalEntityTypes?.map((item) => {
        return { value: item.legalEntityTypeId, text: item.legalEntityTypeDisplayName };
      }),
    [legalEntityTypes],
  );

  const advancedSearchInputParams: AdvancedSearchParam[] = useMemo(
    () => [
      {
        label: `${t('organization.fieldLabel.legalName')}`,
        name: 'name',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.sellerId')}`,
        name: 'sellerId',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.phone')}`,
        name: 'phone',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('organization.fieldLabel.legalOrganization')}`,
        name: 'organizationType',
        type: AdvancedSearchFieldType.Select,
        selectTypeParams: legalOrganizationOptions,
      },
      {
        label: `empty1`,
        name: 'empty1',
        type: AdvancedSearchFieldType.EmptySpace,
        selectTypeParams: null,
      },
      {
        label: `empty2`,
        name: 'empty2',
        type: AdvancedSearchFieldType.EmptySpace,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellergroup.table.physicaladdress1')}`,
        name: 'physicalAddress',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalzipcode')}`,
        name: 'physicalZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalcity')}`,
        name: 'physicalCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.physicalstate')}`,
        name: 'physicalState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
      {
        label: `${t('relationship.sellergroup.table.mailingaddress1')}`,
        name: 'mailingAddress',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingzipcode')}`,
        name: 'mailingZipCode',
        type: AdvancedSearchFieldType.Number,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingcity')}`,
        name: 'mailingCity',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: `${t('relationship.sellers.seller.table.mailingstate')}`,
        name: 'mailingState',
        type: AdvancedSearchFieldType.StateSelect,
        selectTypeParams: states.map((el) => ({
          value: el,
          text: `${el.stateCode}: ${el.stateName}`,
        })),
      },
    ],
    [legalOrganizationOptions, states, t],
  );

  const selectArray: QuickTableFilter[] = useMemo(() => {
    if (isSellerGroupTab) {
      return [];
    }
    return [
      {
        label: 'Agent',
        value: agent,
        handleFilter: handleFilterAgent,
        filterOptions: agentOptions,
        type: 'single',
        disabled: !!fetchingId && !user?.isProvider,
      },
      {
        label: 'Seller Group',
        value: sellerGroup,
        handleFilter: handleFilterSellerGroup,
        filterOptions: sellerGroupOptions,
        type: 'multi',
      },
    ];
  }, [
    agent,
    agentOptions,
    fetchingId,
    handleFilterAgent,
    handleFilterSellerGroup,
    isSellerGroupTab,
    sellerGroup,
    sellerGroupOptions,
    user?.isProvider,
  ]);

  useEffect(() => {
    if (!sellers) {
      return;
    } else {
      sellerRowsMapper(sellers.data);
      setTotalCount(sellers.totalCount);
    }
  }, [sellerRowsMapper, sellers]);

  useEffect(() => {
    if (!advancedSearchVisibility) {
      setDataAdvancedSearch({
        name: '',
        phone: '',
        sellerId: '',
        physicalZipCode: '',
        physicalStateId: undefined,
        physicalAddress: '',
        physicalCity: '',
        mailingAddress: '',
        mailingCity: '',
        mailingStateId: undefined,
        mailingZipCode: '',
        legalEntityTypeId: undefined,
      });
    } else {
      setSearchTerm('');
      setStatus({ label: Status.Any, value: Status.Any });
    }
  }, [advancedSearchVisibility, setSearchTerm, setStatus]);

  const [goError] = useState(false);
  const valueRef = useRef<HTMLInputElement>(null);
  const tableHeadingsArray = useMemo(() => {
    if (!isSellerGroupTab) {
      return [
        { Seller: 'sellerName' },
        { 'Seller ID': 'customerOrgId' },
        { Phone: 'phone' },
        { ZIP: 'PhysicalZipCode' },
        { State: 'PhysicalState' },
        { City: 'PhysicalCity' },
        { 'Seller Group': 'sellerGroupName' },
      ] as Record<string, string>[];
    }
    return [
      { Seller: 'sellerName' },
      { 'Seller ID': 'customerOrgId' },
      { Phone: 'phone' },
      { ZIP: 'PhysicalZipCode' },
      { State: 'PhysicalState' },
      { City: 'PhysicalCity' },
    ] as Record<string, string>[];
  }, [isSellerGroupTab]);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  const onSubmitAdvancedSearch = useCallback(
    (data: any) => {
      let flag = 0;
      Object.keys(data).forEach((item: any) => {
        if (data[item] !== '') {
          setErr(false);
          flag = 1;
        }
      });
      if (data?.physicalLocationState?.label) {
        setErr(false);
        flag = 1;
      }
      if (data?.mailingState?.label) {
        setErr(false);
        flag = 1;
      }
      if (flag === 0) {
        sellerRowsMapper([]);
        setErr(true);
      } else {
        const dataToPost: AdvancedSearchPayloadData = {
          name: data.name,
          phone: data.phone.replaceAll('-', ''),
          sellerId: data.sellerId,
          physicalZipCode: data.physicalZipCode,
          physicalStateId: data.physicalState?.value?.addressStateId,
          physicalAddress: data.physicalAddress,
          physicalCity: data.physicalCity,
          mailingAddress: data.mailingAddress,
          mailingCity: data.mailingCity,
          mailingStateId: data.mailingState?.value?.addressStateId,
          mailingZipCode: data.mailingZipCode,
          legalEntityTypeId: data.organizationType.value,
        };
        setDataAdvancedSearch(dataToPost);
      }
      setPage(0);
    },
    [sellerRowsMapper, setPage],
  );

  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setPage, setRowsPerPage],
  );

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
      setPage(0);
    },
    [setOrder, setOrderBy, setPage],
  );

  const onResetAdvancedSearch = useCallback(() => {
    setPage(0);
    resetSearch();
    onSubmitAdvancedSearch(initialSellerSearchValues);
  }, [onSubmitAdvancedSearch, resetSearch, setPage]);

  useEffect(() => {
    if (agentId) {
      resetSearch();
      const selectedAgent = agentListForFilter?.find((item) => item.id === Number(agentId));
      if (selectedAgent) {
        setAgent({ label: selectedAgent?.name, value: selectedAgent?.id });
      }
    }
  }, [resetSearch, agentId, agentListForFilter, setAgent]);

  useEffect(() => {
    if (fetchingId) {
      const selectedAgent = agentListForFilter?.find((item) => item.id === Number(fetchingId));
      if (selectedAgent) {
        setAgent({ label: selectedAgent?.name, value: selectedAgent?.id });
      }
    }
  }, [agentListForFilter, fetchingId, setAgent]);

  useEffect(() => {
    if (sellerGroupId) {
      const selectedSG = sellerGroups?.find((item) => item.id === Number(sellerGroupId));

      if (selectedSG) {
        setSellerGroup([
          {
            value: selectedSG.id.toString(),
            label: selectedSG.name,
          },
        ]);
      }
    }
  }, [sellerGroupId, sellerGroups, setSellerGroup]);

  return (
    <CustomTable
      isAdvanceSearch={!isSellerGroupTab}
      isDataLoading={isSellersLoading || isPreSellersLoading}
      order={order}
      orderBy={orderBy}
      handleRequestSort={onHandleSetOrder}
      goError={goError}
      page={page}
      rowsPerPage={rowsPerPage}
      data={sellerRows}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
      valueRef={valueRef}
      setPage={setPage}
      tableHeadingsArray={tableHeadingsArray}
      advancedSearchVisibility={advancedSearchVisibility}
      setAdvancedSearchVisibility={setAdvancedSearchVisibility}
      onSubmitAdvancedSearch={onSubmitAdvancedSearch}
      onResetAdvancedSearch={onResetAdvancedSearch}
      openAdvancedSearch={sellerRowsMapper}
      searchValue={searchTerm}
      handleSearchValue={handleSearchValue}
      selectArray={selectArray}
      setPagination={setPagination}
      isDataPagination={pagination}
      addNewLink={addSellerButtonState}
      tableTitle={!isSellerGroupTab ? 'Sellers' : ''}
      placeHolder={t('relationship.sellers.seller.search.sellers')}
      total={totalCount}
      err={err}
      InputsComponent={(props: InputsComponentProps) => (
        <AdvancedSearchInputs
          {...{
            formMethods: props.formMethods,
            advancedSearchInputParams,
          }}
        />
      )}
    />
  );
};
