import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomModal from '../Modal';
import { AppActionButton } from '../../shared/AppActionButton';
import { ModalBox, ModalButtonsBox } from '../Modal/styles';

export default function UserActiveModal(props: any) {
  const { t } = useTranslation();
  const { popUp, handleConfirm, handleModal, ownUser } = props;
  return (
    <ModalBox>
      <CustomModal
        open={popUp}
        containerWidth="400px"
        modalTitle={t('security.roles.roleForm.role.warning')}
      >
        <>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} color="#000000" fontWeight="bold">
            {ownUser
              ? t('security.users.userForm.active.ownUser.message')
              : t('security.users.userForm.active.message')}
          </Typography>
          <ModalButtonsBox>
            <AppActionButton
              style={{ marginRight: '15px' }}
              variant="outlined"
              onClick={handleConfirm}
            >
              {t('user.actions.confirm')}
            </AppActionButton>
            <AppActionButton variant="outlined" onClick={handleModal}>
              {t('user.actions.cancel')}
            </AppActionButton>
          </ModalButtonsBox>
        </>
      </CustomModal>
    </ModalBox>
  );
}
