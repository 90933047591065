import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

export default function AverageTotalInventoryRangePage() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const { data: averageTotalInventoryRange, isFetching } =
    ConfigurationService.useAverageAssetInventoryRange({
      enabled: isEnabled,
    });

  const rows = useMemo(() => {
    return (
      averageTotalInventoryRange?.map((item) => ({
        averageAssetInventoryRangeValue: item.averageAssetInventoryRangeValue,
        id: item.averageAssetInventoryRangeId,
      })) || []
    );
  }, [averageTotalInventoryRange]);

  const columns: GridColDef[] = [
    {
      field: 'averageAssetInventoryRangeValue',
      headerName: 'Average Total Inventory Range',
      width: 400,
    },
  ];

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Average Total Inventory" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autoPageSize={true}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
