import {
  Darksome,
  GrotesqueGreen,
  MainBackground,
  MainTextColor,
  SecondaryBackground,
} from '../../../Theme/colorsVariables';
import { Box, Typography, Modal, Grid } from '@mui/material';
import styled from 'styled-components';
import { breakPointPaginationTop } from '../../../utils/MediaBreakPoint';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { DeepElectricBlue, White } from '../../../Theme/colorsVariables';

export const ModalFlexBox = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled(Box)`
  background: ${MainBackground};
  margin: 20px;
  max-width: 1200px;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  &:focus-visible {
    outline: none;
  }
`;

export const BackContainer = styled(Box)`
  cursor: pointer;
  margin-right: 5px;
  svg {
    color: ${White};
  }
`;

export const ModalTitleContainer = styled(Box)`
  padding: 15px;
  display: flex;
  background: ${SecondaryBackground};
  align-items: center;
  justify-content: space-between;
  ${breakPointPaginationTop('padding: 9px')}
`;

export const ModalTitle = styled(Box)`
  font-family: Gotham-Bold;
  color: ${White};
`;

export const ModalForm = styled(Box)`
  padding: 15px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const StyledOfac = styled(Box)`
  min-height: 400px;
`;

export const OfacTitle = styled(Box)`
  font-family: Gotham-Bold;
  color: ${White};
`;

export const StyledOfacBox = styled(Box)`
  border: 1px solid ${Darksome};
  background: ${White};
`;

export const StyledOfacIdentificationBox = styled(Box)`
  overflow: auto;
  max-height: 200px;
  border: 1px solid ${Darksome};
`;

export const StyledGridItem = styled(Grid)``;

export const HeaderTypograpy = styled(Typography)`
  color: ${MainTextColor};
  font-size: 14px;
  font-family: Gotham-Book;
  margin-left: -15px;
  @media only screen and (max-width: 1366px) {
    position: static;
  }
`;

export const LabelTypography = styled(Typography)`
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  font-weight: 500;
  font-size: 16px;
`;

export const HeaderTypography = styled(LabelTypography)`
  margin-left: 0px;
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  font-weight: 600;
  font-size: 16px;
`;

export const EntityLabel = styled(Box)`
  cursor: pointer;
  text-decoration: underline;
  color: ${DeepElectricBlue};
`;

export const NoDataText = styled.span`
  font-size: 14px;
  font-family: Gotham-MonoSpace-Numbers, sans-serif;
  font-weight: 500;
`;

export const buttonMatchSxStyle = {
  height: '46px',
  background: `${GrotesqueGreen} !important`,
};

export const buttonNoMatchSxStyle = {
  height: '46px',
};

export const StyledScrollBox = styled(Box)`
  max-height: 500px;
  overflow-y: scroll;
`;

export const StyledCancelRoundedIcon = styled(CancelRoundedIcon)`
  color: ${White};
  cursor: pointer;
`;

export const StyledDropdownIconContainer = styled(Box)`
  color: #77868f;
  cursor: pointer;
`;
