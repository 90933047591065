import React from 'react';
import { FakeTextFieldLabel, FakeTextFieldStyled, FakeTextFieldValue } from './styles';

const AppFakeTextField = ({ label, value }: { label: JSX.Element; value: JSX.Element }) => {
  return (
    <FakeTextFieldStyled>
      <FakeTextFieldLabel>{label}</FakeTextFieldLabel>
      <FakeTextFieldValue>{value}</FakeTextFieldValue>
    </FakeTextFieldStyled>
  );
};

export default AppFakeTextField;
