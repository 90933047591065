import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PageHeaderContentHost from '../../../shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../../shared/AppPageHeaderContentHost/styles';
import { AlignCenterBox, JustifyContentRightBox, SpaceBetweenBox } from '../../../shared/FlexBox';
import {
  useAvailableProductLines,
  Organizations,
  useGeneratePreview,
  useIsSendForSignaturesEnabled,
  useOrganizationInfo,
  useSellerGroupSignersList,
} from '../../../../services/sellerGroupServices';
import { SecondHeaderBox } from '../../../../pages/OrganizationContactInfoPage/styles';
import { useTranslation } from 'react-i18next';
import { AppCancelButton } from '../../../shared/AppCancelButton';
import { Box, Checkbox, FormGroup, Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AddContactBtn, ButtonWrapper, ProductGrid, ProductLinesBoxScrollable } from './styles';
import { AddCircleOutlineRounded } from '@mui/icons-material';
import { AppTextField } from '../../../shared/AppTextField';
import AppAutocompleteSingleSelect from '../../../shared/AppAutocompleteSingleSelect';
import { ApiError } from '../../../../types';
import { AddProductLineFormValues, FormSingleSelectValue } from '../../../../types/sellerGroup';
import { Control, Controller, FieldValues, useForm } from 'react-hook-form';
import { addProductLineValidationSchema } from '../../../../Resolvers/addProductLineValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import usePermissions, { PermissionKeys } from '../../../../hooks/usePermissions';
import dayjs from 'dayjs';
import { DatePicker, PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import { MainRed, White } from '../../../../Theme/colorsVariables';
import { useAllAgents } from '../../../../services/sellerServices';
import { pastingCheck } from '../../../../utils';
import { useAgentContactsAll } from '../../../../services/agentService';
import { StickyContainer } from '../../../../Theme/oldStylesInUse/oldAddSellerStyles';
import { FeinBox } from '../../../common/styles/feinBox';
import { StyledTypography } from '../../../Forms/RoleForm/noPermissionsText';

import { useDataloss } from '../../../../hooks/useDataloss';
import { isEqual } from 'lodash';
import { CancelModal } from '../../../modal/CancelModal';
import { AppActionButton } from '../../../shared/AppActionButton';
import { ProviderFetchingQuery } from '../../../../services/sellerServices';
import { useToast } from '../../../../hooks/useToast';
import AuthService from '../../../../services/authService';
import { useCheckOrgHasInProgressAgreements } from '../../../../services/organization';
import { AppWarningParagraph } from '../../../shared/AppWarningParagraph';
import { UserContext } from '../../../../context/User';
import { QuickConfirmationModalContext } from '../../../../context/QuickModal';
import { useDocuSignSendForSignatures } from '../../../../services/DocuSignService';
import { CheckboxMain } from '../../../PermissionSets/styles';

export const AddProductLines = ({
  customOrgId,
  setIsUnsavedData,
}: {
  customOrgId?: string;
  setIsUnsavedData?: Dispatch<SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { id: urlSellerId } = useParams();
  const id = useMemo(() => {
    return customOrgId ? customOrgId : urlSellerId;
  }, [customOrgId, urlSellerId]);
  const location = useLocation();
  const { state } = location;
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const isCreateOrganizationContact = usePermissions(PermissionKeys.CreateContact);
  const isReadAgent = usePermissions(PermissionKeys.ReadAgent);

  const { data: providerInfo } = ProviderFetchingQuery();
  const { data: commonData } = AuthService.useCommonDetailsQuery();

  const agreementEffectiveDateRangeOver = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeOver');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);
  const agreementEffectiveDateRangeUnder = useMemo(() => {
    const obj = commonData?.find((item) => item.type === 'AgreementEffectiveDateRangeUnder');
    if (obj) {
      return +obj.value;
    }
    return null;
  }, [commonData]);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [defaultAgentRelationshipOwner, setDefaultAgentRelationshipOwner] =
    useState<FormSingleSelectValue | null>(null);
  const [defaultSigner, setDefaultSigner] = useState<FormSingleSelectValue | null>(null);
  const [defaultSelectedAgent, setDefaultSelectedAgent] = useState<FormSingleSelectValue | null>(
    null,
  );

  const addProductLineForm = useMemo<AddProductLineFormValues | undefined>(() => {
    return state;
  }, [state]);

  const formDefaultValues = useMemo<AddProductLineFormValues>(() => {
    return {
      productLines: [],
      legacyProductLines: [],
      legacyProductLinesFlag: false,
      signer: defaultSigner || null,
      message: '',
      effectiveDate: dayjs(new Date()),
      agent: defaultSelectedAgent || null,
      agentRelationshipOwner: defaultAgentRelationshipOwner || null,
    };
  }, [defaultAgentRelationshipOwner, defaultSelectedAgent, defaultSigner]);

  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<AddProductLineFormValues>({
    mode: 'all',
    defaultValues: formDefaultValues,
    resolver: yupResolver(addProductLineValidationSchema),
  });

  const allFields = watch();

  const {
    agent: selectedAgent,
    signer,
    message,
    productLines,
    agentRelationshipOwner,
    effectiveDate,
    legacyProductLines: legacyProductLinesFormValue,
  } = allFields;

  const { data: isSendForSignaturesEnabledData } = useIsSendForSignaturesEnabled({
    agencyContactId: agentRelationshipOwner && signer ? agentRelationshipOwner?.value : 0,
    signerContactId: agentRelationshipOwner && signer ? signer?.value : 0,
  });

  const { data: signersListByAgent } = useSellerGroupSignersList({
    sellerGroupId: Number(id) || 0,
    agentId: selectedAgent?.value || 0,
    options: {
      enabled: !!selectedAgent?.value && !!id,
    },
  });

  const { data: sellerGroupData } = useOrganizationInfo({
    id: Number(id),
    isEnabled: true,
    organisation: Organizations.SellerGroupOrg,
  });

  const { data: agencies } = useAllAgents({});

  const { data: agentContacts, isFetching: isAgentContactsFetching } = useAgentContactsAll({
    agentId: Number(selectedAgent?.value),
    options: { enabled: !!selectedAgent?.value },
  });

  const { data: availableProductLines, isFetching: isProductsFetching } = useAvailableProductLines(
    Number(id),
    selectedAgent?.value,
    {
      onSuccess(data) {
        setValue(
          'productLines',
          data.filter((item) => !item.isLegacyAgreementProduct).map((item) => item.productId),
        );
        const legacyProducts = data.filter((item) => item.isLegacyAgreementProduct);
        if (legacyProducts.length) {
          setValue(
            'legacyProductLines',
            legacyProducts.map((item) => item.productId),
          );
          setValue('legacyProductLinesFlag', true);
        } else {
          setValue('legacyProductLinesFlag', false);
        }
      },
    },
  );

  const legacyProductLines = useMemo(() => {
    if (availableProductLines?.length) {
      return availableProductLines
        .filter((item) => item.isLegacyAgreementProduct)
        .map((item) => ({
          name: item.productName,
          id: item.productId,
        }));
    }
    return [];
  }, [availableProductLines]);

  const onSuccessGeneratePreview = (previewURL: string) => {
    window.open(previewURL, '_blank', 'noreferrer');
  };
  const onErrorGeneratePreview = (error: ApiError) => {
    setQuickModal({
      isOpen: true,
      modalTitle: 'Error!',
      message: error.response.data.message,
    });
  };

  const { mutate: getDocumentUrl } = useGeneratePreview(
    onSuccessGeneratePreview,
    onErrorGeneratePreview,
  );

  const { mutate: sendForSignature } = useDocuSignSendForSignatures({
    options: {
      onSuccess(data) {
        toast.success(data.message);
        navigate(`/dashboard/SellerGroup/${id}/ProductLine`);
      },
      onError(error) {
        setQuickModal({
          isOpen: true,
          modalTitle: 'Error!',
          message: error.response.data.message,
        });
      },
    },
  });

  const signerOptions = useMemo(
    () =>
      signersListByAgent?.map((contact) => ({
        label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
          contact.title || ''
        }<span class="vl"></span>${contact.email}`,
        value: Number(contact.contactId),
      })) || [],
    [signersListByAgent],
  );

  const agents = useMemo(() => {
    return (
      agencies?.map((agent) => {
        return {
          label: `${agent.agencyName}<span class="vl"></span>${pastingCheck(
            agent?.phoneNumber || '',
          )}`,
          value: agent.agencyId,
        };
      }) || []
    );
  }, [agencies]);

  const agentRelationshipOwners = useMemo(() => {
    return (
      agentContacts?.map((contact) => {
        return {
          label: `${contact.firstName} ${contact.lastName}<span class="vl"></span>${
            contact.title || ''
          }<span class="vl"></span>${contact.email}`,
          value: Number(contact.contactId),
        };
      }) || []
    );
  }, [agentContacts]);

  const sortedProducts = useMemo(() => {
    return availableProductLines
      ? availableProductLines
          .filter((item) => !item.isLegacyAgreementProduct)
          .sort((a, b) => a.productName.localeCompare(b.productName))
      : [];
  }, [availableProductLines]);

  const isAgentUserHaveContact = useMemo(() => {
    return agentContacts?.find(
      (agentContact) =>
        user?.name === `${agentContact.firstName} ${agentContact.lastName}` &&
        user?.email === agentContact.email,
    );
  }, [agentContacts, user]);

  useEffect(() => {
    if (user && user.userType === 'Agency') {
      const userAgent = agents.find((agentObject) => agentObject.value === user.orgId);

      if (userAgent) {
        setValue('agent', userAgent);
        setDefaultSelectedAgent(userAgent);
      }

      if (isAgentUserHaveContact && !formDefaultValues.agentRelationshipOwner) {
        setDefaultAgentRelationshipOwner({
          label: `${isAgentUserHaveContact.firstName} ${isAgentUserHaveContact.lastName}<span class="vl"></span>${isAgentUserHaveContact.title}<span class="vl"></span>${isAgentUserHaveContact.email}`,
          value: Number(isAgentUserHaveContact.contactId),
        });
        setValue('agentRelationshipOwner', {
          label: `${isAgentUserHaveContact.firstName} ${isAgentUserHaveContact.lastName}<span class="vl"></span>${isAgentUserHaveContact.title}<span class="vl"></span>${isAgentUserHaveContact.email}`,
          value: Number(isAgentUserHaveContact.contactId),
        });
      }
    }
    if (user && user.userType === 'Provider') {
      if (agentRelationshipOwners.length === 1) {
        setValue('agentRelationshipOwner', agentRelationshipOwners[0], { shouldValidate: true });
        setDefaultAgentRelationshipOwner(agentRelationshipOwners[0]);
      }
    }
  }, [agentRelationshipOwners, agents, formDefaultValues, isAgentUserHaveContact, setValue, user]);

  useEffect(() => {
    if (signerOptions.length === 1) {
      setDefaultSigner(signerOptions[0]);
      setValue('signer', signerOptions[0], { shouldValidate: true });
    } else {
      setDefaultSigner(null);
      setValue('signer', null);
    }
  }, [setValue, signerOptions]);

  useEffect(() => {
    if (addProductLineForm && addProductLineForm.hasOwnProperty('agent')) {
      setValue('agent', addProductLineForm.agent);
      setValue('agentRelationshipOwner', addProductLineForm.agentRelationshipOwner);
      setValue('effectiveDate', addProductLineForm.effectiveDate);
      setValue('message', addProductLineForm.message);
      setValue('signer', addProductLineForm.signer);
      setValue('productLines', addProductLineForm.productLines);
      window.history.replaceState({}, '', location.pathname);
    }
  }, [addProductLineForm, location.pathname, setValue]);

  useEffect(() => {
    if (agentRelationshipOwner) {
      const valueDoesNotExist = agentContacts?.every((item) => {
        if (item.contactId === agentRelationshipOwner.value) {
          return false;
        }
        return true;
      });
      if (valueDoesNotExist) {
        setValue('agentRelationshipOwner', null, { shouldValidate: true });
      }
    }
  }, [agentContacts, agentRelationshipOwner, setValue]);

  const isUnsavedData = useMemo(() => {
    const currentFormDefaultValues = {
      ...formDefaultValues,
      agent: defaultSelectedAgent,
      agentRelationshipOwner: defaultAgentRelationshipOwner,
      signer: defaultSigner,
      effectiveDate: {
        year: formDefaultValues.effectiveDate?.get('year'),
        month: formDefaultValues.effectiveDate?.get('month'),
        day: formDefaultValues.effectiveDate?.get('day'),
      },
      productLines: availableProductLines?.map((item) => item.productId).sort((a, b) => a - b),
    };

    const currentFormValues = {
      ...allFields,
      effectiveDate: {
        year: allFields.effectiveDate?.get('year'),
        month: allFields.effectiveDate?.get('month'),
        day: allFields.effectiveDate?.get('day'),
      },
      productLines: allFields.productLines?.sort((a, b) => a - b),
    };

    return !isEqual(currentFormValues, currentFormDefaultValues);
  }, [
    formDefaultValues,
    defaultSelectedAgent,
    defaultAgentRelationshipOwner,
    defaultSigner,
    availableProductLines,
    allFields,
  ]);

  const onDiscardChanges = useCallback(() => {
    navigate(`/dashboard/SellerGroup/${id}/ProductLine`);
    setConfirmPopup(false);
  }, [id, navigate]);

  const onSaveChanges = useCallback(() => {}, []);

  const onExit = useCallback(() => {}, []);

  useDataloss(isUnsavedData, onDiscardChanges, onSaveChanges, onExit, true);

  useEffect(() => {
    if (setIsUnsavedData) setIsUnsavedData(isUnsavedData);
  }, [isUnsavedData, setIsUnsavedData]);

  const previewAgreement = () => {
    getDocumentUrl({
      redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      contactId: Number(signer?.value),
      effectiveDate: effectiveDate?.toISOString() || '',
      orgId: parseInt(id || ''),
      message: message,
      productIds: [
        ...productLines.map((x) => x.toString()),
        ...legacyProductLines.map((x) => x.id.toString()),
      ],
      providerOrgId: providerInfo?.orgId || 1,
      agencyContactId: Number(agentRelationshipOwner?.value),
    });
  };

  const onSendForSignatures = () => {
    sendForSignature({
      redirectUrl: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      orgId: parseInt(id || ''),
      productIds: [...productLines, ...legacyProductLines.map((item) => item.id)],
      contactId: Number(signer?.value),
      message: message,
      agencyContactId: Number(agentRelationshipOwner?.value),
      effectiveDate: effectiveDate?.toISOString() || '',
      providerOrgId: providerInfo?.orgId || 1,
    });
  };

  return (
    <>
      {!customOrgId && (
        <PageHeaderContainer mb={3}>
          <SpaceBetweenBox>
            <PageHeaderContentHost
              title="Seller Group"
              name={sellerGroupData?.legalName}
              status={sellerGroupData?.status}
            />
          </SpaceBetweenBox>
        </PageHeaderContainer>
      )}
      <SecondHeaderBox>
        <PageHeaderContentHost color={`${White}`} title={'Add Product Lines'} />
        <AlignCenterBox>
          {!customOrgId && (
            <AppCancelButton
              onClick={() => {
                if (isUnsavedData) {
                  setConfirmPopup(true);
                } else {
                  setConfirmPopup(false);
                  navigate(`/dashboard/SellerGroup/${id}/ProductLine`);
                }
              }}
            />
          )}
          <AppActionButton
            disabled={
              legacyProductLinesFormValue.length
                ? !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
                : !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  productLines?.length === 0 ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
            }
            onClick={() => {
              previewAgreement();
            }}
          >
            {t('sellerGroup.productLine.previewAgreementBtn')}
          </AppActionButton>
          <AppActionButton
            disabled={
              legacyProductLinesFormValue.length
                ? !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
                : !selectedAgent ||
                  !agentRelationshipOwner ||
                  !signer ||
                  productLines?.length === 0 ||
                  !effectiveDate?.date() ||
                  !isSendForSignaturesEnabledData?.enableSendForSignatures
            }
            onClick={() => {
              onSendForSignatures();
            }}
          >
            {t('sellerGroup.productLine.sendForSignatures')}
          </AppActionButton>
        </AlignCenterBox>
      </SecondHeaderBox>
      {isSendForSignaturesEnabledData?.message && (
        <JustifyContentRightBox>
          <AppWarningParagraph>
            <i>{isSendForSignaturesEnabledData?.message}</i>
          </AppWarningParagraph>
        </JustifyContentRightBox>
      )}
      <Grid container spacing={isSmall ? 1 : 2}>
        <ProductGrid item md={5} mt={2} display={'flex'} flexDirection={'column'}>
          <Grid container spacing={isSmall ? 1 : 2}>
            <Grid item md={7}>
              <AppAutocompleteSingleSelect
                control={control as unknown as Control<FieldValues>}
                name={'agent'}
                label={'Agent'}
                options={agents}
                rules={{
                  required: true,
                }}
                disabled={user?.userType === 'Agency'}
                errorMessage={errors.agent?.message}
              />
            </Grid>
            <Grid item md={5} />

            <Grid item md={7}>
              <AppAutocompleteSingleSelect
                control={control as unknown as Control<FieldValues>}
                name={'agentRelationshipOwner'}
                label={'Agent Relationship Owner'}
                options={agentRelationshipOwners}
                disabled={!selectedAgent || agentRelationshipOwners.length === 0}
                rules={{
                  required: true,
                }}
                errorMessage={errors.agentRelationshipOwner?.message}
              />
            </Grid>

            <Grid item md={5}>
              <AlignCenterBox height={'100%'}>
                <AddContactBtn
                  variant="outlined"
                  disabled={!isCreateOrganizationContact || !isReadAgent || !selectedAgent}
                  startIcon={<AddCircleOutlineRounded />}
                  onClick={() => {
                    navigate(
                      `/dashboard/Agent/${selectedAgent?.value}/AddContact?returnUrl=/dashboard/SellerGroup/${id}/AddProductLine`,
                      { state: allFields },
                    );
                  }}
                >
                  {t('organization.addproduct.addARObuttonLabel')}
                </AddContactBtn>
              </AlignCenterBox>
            </Grid>
            {agentRelationshipOwners.length === 0 &&
              !isAgentContactsFetching &&
              !!selectedAgent && (
                <Grid item md={12}>
                  <FeinBox>{t('sellerGroup.productLine.noAgentContactWarning')}</FeinBox>
                </Grid>
              )}
          </Grid>
          <Box sx={{ flexGrow: 2, position: 'relative' }} mt={2}>
            <ProductLinesBoxScrollable>
              <StickyContainer>
                <b>{t('sellerGroup.productLine.selectProductLine')}</b>
              </StickyContainer>
              {!sortedProducts.length && !isProductsFetching && (
                <Grid container>
                  <Grid item xs={12}>
                    <StyledTypography>{t('sellerGroup.productLine.noRecords')}</StyledTypography>
                  </Grid>
                </Grid>
              )}
              <FeinBox>{errors.productLines?.message}</FeinBox>
              <Controller
                name="productLines"
                control={control}
                render={({ field }) => (
                  <>
                    {sortedProducts.map((item) => (
                      <Grid item md={12} xs={12} lg={12} key={item.productId}>
                        <AlignCenterBox>
                          <FormGroup>
                            <CheckboxMain
                              control={
                                <Checkbox
                                  color="success"
                                  value={item.productId}
                                  checked={field.value?.indexOf(item.productId) >= 0}
                                  onChange={(event, checked) => {
                                    if (checked) {
                                      field.onChange([...field.value, +event.target.value]);
                                    } else {
                                      field.onChange(
                                        field.value.filter(
                                          (value) => value !== +event.target.value,
                                        ),
                                      );
                                    }
                                  }}
                                />
                              }
                              label={item.productName}
                            />
                          </FormGroup>
                        </AlignCenterBox>
                      </Grid>
                    ))}
                  </>
                )}
              />
              {!!legacyProductLines.length && (
                <>
                  <StickyContainer>
                    <b>{t('sellerGroup.productLine.legacyProductLine')}</b>
                  </StickyContainer>
                  <Controller
                    name="legacyProductLines"
                    control={control}
                    render={({ field }) => (
                      <>
                        {legacyProductLines.map((item) => (
                          <Grid item md={12} xs={12} lg={12} key={item.id}>
                            <AlignCenterBox>
                              <FormGroup>
                                <CheckboxMain
                                  disabled
                                  control={
                                    <Checkbox
                                      color="success"
                                      value={item.id}
                                      checked={field.value?.indexOf(item.id) >= 0}
                                      onChange={(event, checked) => {
                                        if (checked) {
                                          field.onChange([...field.value, +event.target.value]);
                                        } else {
                                          field.onChange(
                                            field.value.filter(
                                              (value) => value !== +event.target.value,
                                            ),
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  label={item.name}
                                />
                              </FormGroup>
                            </AlignCenterBox>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </>
              )}
            </ProductLinesBoxScrollable>
          </Box>
        </ProductGrid>

        <Grid item md={7} mt={2}>
          <Grid container spacing={isSmall ? 1 : 2}>
            <Grid item md={8}>
              <AppAutocompleteSingleSelect
                control={control as unknown as Control<FieldValues>}
                name={'signer'}
                label={'Signer'}
                options={signerOptions}
                rules={{
                  required: true,
                }}
                disabled={signerOptions.length === 0}
                errorMessage={errors.signer?.message}
                helperText={
                  signerOptions.length === 0
                    ? user?.userType === 'Agency'
                      ? t('sellerGroup.productLine.noContactWarning')
                      : t('sellerGroup.productLine.noContactWarningProvider')
                    : undefined
                }
              />
            </Grid>
            <Grid item md={4}>
              <ButtonWrapper>
                <AddContactBtn
                  variant="outlined"
                  disabled={!isCreateOrganizationContact}
                  startIcon={<AddCircleOutlineRounded />}
                  onClick={() => {
                    navigate(
                      `/dashboard/SellerGroup/${id}/AddContact?returnUrl=/dashboard/SellerGroup/${id}/AddProductLine`,
                      { state: allFields },
                    );
                  }}
                >
                  {t('relationship.agencies.innerpages.users.addContact')}
                </AddContactBtn>
              </ButtonWrapper>
            </Grid>

            <Grid item md={8}>
              <Controller
                name="effectiveDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Effective Date"
                    minDate={
                      agreementEffectiveDateRangeUnder &&
                      dayjs().subtract(agreementEffectiveDateRangeUnder, 'day')
                    }
                    maxDate={
                      agreementEffectiveDateRangeOver &&
                      dayjs().add(agreementEffectiveDateRangeOver, 'day')
                    }
                    slots={{
                      textField: AppTextField,
                      day: (props) => (
                        <PickersDay
                          {...props}
                          sx={{
                            [`&&.${pickersDayClasses.selected}`]: {
                              backgroundColor: MainRed,
                            },
                          }}
                        />
                      ),
                    }}
                    slotProps={{
                      day: {
                        sx: {
                          backgroundColor: 'red',
                        },
                      },
                      textField: {
                        required: true,
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={12}>
              <Controller
                control={control}
                name="message"
                render={({ field }) => {
                  return <AppTextField {...field} multiline minRows={6} label={'Message'} />;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {confirmPopup && (
        <CancelModal
          modalTitle="Warning"
          onDiscardChanges={onDiscardChanges}
          onSaveChanges={onSaveChanges}
          onExit={() => {
            setConfirmPopup(false);
          }}
          hideSaveDataButton={true}
          isOpened={confirmPopup}
        />
      )}
    </>
  );
};

export const AddProductLinesProtected = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const toast = useToast();

  const isSendForSignatures = usePermissions(PermissionKeys.SendForSignatures);

  const { data: isAgreementInProcess, isFetching: isAgreementInProcessFetching } =
    useCheckOrgHasInProgressAgreements(Number(id), {
      enabled: isSendForSignatures,
      onError(err) {
        toast.error(err.response.data.message);
      },
    });

  if (!isSendForSignatures) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
        </Grid>
      </Grid>
    );
  }

  if (isAgreementInProcessFetching) {
    return <></>;
  }

  if (isAgreementInProcess) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledTypography>{t('productLine.inProgress.warning')}</StyledTypography>
        </Grid>
      </Grid>
    );
  }
  return <AddProductLines />;
};
