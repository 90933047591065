import { Grid } from '@mui/material';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import useTrim from '../../hooks/useTrim';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import { useOrganizationContacts } from '../../services/sellerGroupContactsService';
import { linkColor } from '../../Theme/colorsVariables';
import { Cell, CellType, NewLinkState } from '../../types/customTable';
import { EntityType, Order, Status } from '../../types/query-params';
import { OrganizationContact } from '../../types/sellerGroupContacts';
import { getSearchString } from '../../utils';
import {
  QuickTableFilter,
  StatusSelectData,
} from '../../components/shared/CustomTableFilters/interface';
import CustomTable from '../../components/shared/CustomTable';
import { AllowedSearchKeys, useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';
import { AppPhoneInputViewOnly } from '../../components/shared/AppPhoneFieldViewOnly';
import AuthService from '../../services/authService';
import { UserContext } from '../../context/User';
import { useAllAgents } from '../../services/sellerServices';
import { OrganizationService } from '../../services/organizationService';

export const OrganizationContactsTab = ({
  orgId,
  viewContactOptionalCallback,
}: {
  orgId?: number;
  viewContactOptionalCallback?: (contact: OrganizationContact) => void;
}) => {
  const user = useContext(UserContext);
  const location = useLocation();
  const outletContext = useOutletContext<
    | {
        headerContainerHeight?: number;
        setContactsCount?: Dispatch<SetStateAction<number>>;
        contactsCount?: number;
      }
    | undefined
  >();

  const headerContainerHeight = useMemo(
    () => outletContext?.headerContainerHeight,
    [outletContext],
  );
  const organizationName = useMemo(() => {
    if (location.pathname.includes('SellerGroup')) {
      return 'SellerGroup';
    } else if (location.pathname.includes('Agent')) {
      return 'Agent';
    } else if (location.pathname.includes('Seller/')) {
      return 'Seller';
    }
  }, [location.pathname]);

  const SEARCH_KEY = useMemo<AllowedSearchKeys>(() => {
    return organizationName === 'SellerGroup'
      ? 'sellerGroupContactList.search'
      : organizationName === 'Agent'
      ? 'agentContactList.search'
      : organizationName === 'Seller'
      ? 'sellerContactList.search'
      : 'sellerGroupContactList.search';
  }, [organizationName]);

  const { data: systemConstants } = AuthService.useCommonDetailsQuery();
  const { data: orgTenant } = OrganizationService.useTenant();

  const contactOwnerFieldLabel = useMemo(() => {
    return systemConstants?.find((item) => item.type === 'ContactOwner')?.value || 'Contact Owner';
  }, [systemConstants]);

  const {
    searchTerm,
    page,
    rowsPerPage,
    status,
    order,
    orderBy,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    resetSearch,
  } = useSaveSearchCriteria({ searchKey: SEARCH_KEY });

  const { t } = useTranslation();
  const { id } = useParams();
  const isReadContact = usePermissions(PermissionKeys.ReadContact);
  const [totalCount, setTotalCount] = useState(0);
  const [contactsRows, setContactsRows] = useState<Cell[][]>([]);
  const [pagination, setPagination] = useState<boolean>(true);
  const [selectedContactOwnerId, setSelectedContactOwnerId] = useState<{
    label: string;
    value: string;
  } | null>({ label: EntityType.Any, value: EntityType.Any });

  const trimValue = useTrim(searchTerm);

  const tableHeadingsArray = useMemo(() => {
    if (user?.userType === 'Agency' || organizationName === 'Agent') {
      return [
        { 'First Name': 'firstName' },
        { 'Last Name': 'lastName' },
        { Email: 'email' },
        { Phone: 'phone' },
        { Title: 'title' },
        { Status: 'status' },
      ] as Record<string, string>[];
    }
    return [
      { 'First Name': 'firstName' },
      { 'Last Name': 'lastName' },
      { Email: 'email' },
      { Phone: 'phone' },
      { Title: 'title' },
      { [contactOwnerFieldLabel]: 'ContactManagementOrgName' },
      { Status: 'status' },
    ] as Record<string, string>[];
  }, [contactOwnerFieldLabel, organizationName, user?.userType]);

  const {
    data: OrganizationContacts,
    isFetching,
    refetch: refethContacts,
  } = useOrganizationContacts({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy,
    sortDirection: order,
    orgId: orgId ? orgId : Number(id),
    fullName: getSearchString(trimValue),
    email: getSearchString(trimValue),
    title: '',
    phone: '',
    status:
      status?.label === Status.All || status?.label === Status.Any ? Status.Default : status?.label,
    shouldMatchAllCriteria: false,
    contactManagementOrgId: selectedContactOwnerId ? Number(selectedContactOwnerId.value) : null,
  });

  const { isLoading: isPreLoading } = useDebouncedQuery(refethContacts, isReadContact);

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  const handleRowsPerPageChange = useCallback(
    (newLimit: number): void => {
      setRowsPerPage(newLimit);
      setPage(0);
    },
    [setPage, setRowsPerPage],
  );

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
      setPage(0);
    },
    [setOrder, setOrderBy, setPage],
  );

  const handleSearchValue = useCallback(
    (value: string) => {
      setSearchTerm(value);
      setPage(0);
    },
    [setPage, setSearchTerm],
  );

  const handleFilterStatus = useCallback(
    (value: { label: Status; value: Status }) => {
      setStatus(value);
      setPage(0);
    },
    [setPage, setStatus],
  );
  const handleFilterContactOwner = useCallback(
    (value: { label: string; value: string }) => {
      setSelectedContactOwnerId(value);
      setPage(0);
    },
    [setPage, setSelectedContactOwnerId],
  );

  const statusFilterOptions: StatusSelectData[] = useMemo(
    () => [
      { value: 'All', text: t('status.any') },
      { value: 'Active', text: t('status.active') },
      { value: 'Inactive', text: t('status.inactive') },
    ],
    [t],
  );

  const { data: agentsData } = useAllAgents({ options: { enabled: organizationName !== 'Agent' } });

  const agentOptions = useMemo(() => {
    return [
      { value: 'All', text: t('status.any') },
      {
        text: `${orgTenant?.legalName}`,
        value: `${orgTenant?.orgId}`,
      },
      ...(agentsData?.map((agent) => {
        return {
          text: `${agent.agencyName}`,
          value: `${agent.agencyId}`,
        };
      }) || []),
    ];
  }, [agentsData, orgTenant?.legalName, orgTenant?.orgId, t]);

  const selectArray: QuickTableFilter[] = useMemo(() => {
    if (organizationName === 'Agent') {
      return [
        {
          label: 'Status',
          value: status,
          handleFilter: handleFilterStatus,
          filterOptions: statusFilterOptions,
          type: 'single',
        },
      ];
    }
    return [
      {
        label: contactOwnerFieldLabel,
        value: selectedContactOwnerId,
        handleFilter: handleFilterContactOwner,
        filterOptions: agentOptions,
        disabled: user?.userType === 'Agency',
        type: 'single',
      },
      {
        label: 'Status',
        value: status,
        handleFilter: handleFilterStatus,
        filterOptions: statusFilterOptions,
        type: 'single',
      },
    ];
  }, [
    organizationName,
    contactOwnerFieldLabel,
    selectedContactOwnerId,
    handleFilterContactOwner,
    agentOptions,
    user?.userType,
    status,
    handleFilterStatus,
    statusFilterOptions,
  ]);

  const contactRowsMapper = useCallback(
    (data: OrganizationContact[]) => {
      const mappedCells = data.map((item) => {
        const contactFirstNameColumnValue = viewContactOptionalCallback
          ? {
              data: (
                <div
                  onClick={() => {
                    viewContactOptionalCallback(item);
                  }}
                >
                  {item.firstName || ''}
                </div>
              ),
              type: CellType.Action,
              styles: { color: linkColor, cursor: 'pointer', textDecoration: 'underline' },
            }
          : {
              data: item.firstName || '',
              type: CellType.Link,
              linkTo: `/dashboard/${organizationName}/${id}/Contact/${item.contactId}`,
              styles: { color: linkColor },
            };
        return [
          { ...contactFirstNameColumnValue },
          {
            data: item.lastName || '',
            type: CellType.Info,
          },
          {
            data: item.email || '',
            type: CellType.Info,
          },
          {
            data: (
              <AppPhoneInputViewOnly
                phone={
                  (item?.phoneNumberCountryCode?.replace('+', '') || '') + (item?.phoneNumber || '')
                }
              />
            ),
            type: CellType.Info,
          },
          {
            data: item.title || '',
            type: CellType.Info,
          },
          ...(user?.userType === 'Provider' && organizationName !== 'Agent'
            ? [{ data: item.contactManagementOrg?.name, type: CellType.Info }]
            : []),
          {
            data: item.status ? 'Active' : 'Inactive',
            type: CellType.StatusFilter,
            defaultStatus: 'Active',
          },
        ];
      });
      setContactsRows(mappedCells);
    },
    [id, organizationName, user?.userType, viewContactOptionalCallback],
  );

  useEffect(() => {
    outletContext?.setContactsCount?.(OrganizationContacts?.totalCount || 0);
    if (OrganizationContacts) {
      contactRowsMapper(OrganizationContacts.data);
      setTotalCount(OrganizationContacts.totalCount);
    }
  }, [contactRowsMapper, OrganizationContacts, outletContext]);

  useEffect(() => {
    resetSearch();
  }, [resetSearch]);

  useEffect(() => {
    if (user?.userType === 'Agency') {
      const optionToSet = agentOptions.find((item) => Number(item.value) === user.orgId);
      if (optionToSet)
        setSelectedContactOwnerId({ label: optionToSet.text, value: optionToSet.value });
    }
  }, [agentOptions, user?.orgId, user?.userType]);

  return isReadContact ? (
    <CustomTable
      page={page}
      rowsPerPage={rowsPerPage}
      data={contactsRows}
      valueRef={undefined}
      goError={false}
      handlePageChange={handlePageChange}
      handleRowsPerPageChange={handleRowsPerPageChange}
      tableHeadingsArray={tableHeadingsArray}
      advancedSearchVisibility={false}
      setAdvancedSearchVisibility={function (): void {
        throw new Error('Function not implemented.');
      }}
      setPagination={setPagination}
      isDataPagination={pagination}
      isAdvanceSearch={false}
      onSubmitAdvancedSearch={function (): void {
        throw new Error('Function not implemented.');
      }}
      onResetAdvancedSearch={function (): void {
        throw new Error('Function not implemented.');
      }}
      searchValue={trimValue}
      handleSearchValue={handleSearchValue}
      setPage={setPage}
      tableTitle={''}
      placeHolder={'Search'}
      searchInfoMessage={'Enter name or email to search'}
      addNewLink={{ link: '', state: NewLinkState.disabled }}
      order={order}
      orderBy={orderBy}
      handleRequestSort={onHandleSetOrder}
      isDataLoading={isFetching || isPreLoading}
      total={totalCount}
      openAdvancedSearch={function (): void {
        throw new Error('Function not implemented.');
      }}
      InputsComponent={function (): JSX.Element {
        throw new Error('Function not implemented.');
      }}
      selectArray={selectArray}
      noResultsMessage={t('table.noResults')}
      extraHeightVar={headerContainerHeight ? headerContainerHeight + 34 : 200}
    />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <StyledTypography>{t('relationships.organisation.view.permission')}</StyledTypography>
      </Grid>
    </Grid>
  );
};
