import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel, Grid, Link, useMediaQuery, useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { organizationValidationSchema } from '../../../Resolvers/organizationValidationSchema';
import AuthService from '../../../services/authService';
import { getCommonErrorMessages, normalizePhone, normilizeSSN } from '../../../utils';
import { getCommonMaxLengthValue } from '../../../utils/inputMaxLength';
import AppAutocompleteSingleSelect from '../../shared/AppAutocompleteSingleSelect';
import { AppFeinField, AppSsnField } from '../../shared/AppFeinField';
import { AppFaxField, AppPhoneField } from '../../shared/AppPhoneField';
import { AppTextField } from '../../shared/AppTextField';
import { OrganizationFormType, OrganizationFormProps } from './types';
import { OrganizationType } from '../../../types/query-params';
import { ConfigurationService } from '../../../services/configurationService';
import { useAllSellerGroups } from '../../../services/sellerGroupServices';
import { FeinSource } from '../../../types/organization';
import { normalizeFein } from '../../../utils/normalizeFein';
import { SectionHeadings } from './styles';
import { AppZipField } from '../../shared/AppZipField';
import { normalizeZip } from '../../../utils/normalizeZip';
import { FetchLookups } from '../../../services/sellerServices';
import AppFakeTextField from '../../shared/AppFakeTextField';
import {
  AddressValidationModalProps,
  AddressValidationModals,
  useValidateAddress,
} from './AddressValidation';
import { useFillGoogleAddress } from './AddressValidation/utils';
import { AddressState } from '../../../types';
import { ModificationLog } from '../../ModificationLog';

export const CreateOrganizationForm: FC<Partial<OrganizationFormProps>> = ({
  editForm,
  orgData,
  formRef,
  setIsFormValid,
  setIsUnsavedChanges,
  customFieldError,
  setCustomFieldError,
  orgType,
  defaultSellerGroupId,
  onSubmit,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));
  const { t } = useTranslation();

  const [addressValidationModalPayload, setAddressValidationModalPayload] =
    useState<AddressValidationModalProps>({
      verdict: 'ADDRESS_COMPLETE',
      addressType: 'physicalLocation',
      enteredAddress: '',
      suggestedAddress: '',
      missingComponents: {},
      onCancel: () => {},
      onSubmit: () => {},
    });

  const googleAutoCompleteForPhysicalAddressRef = useRef<google.maps.places.Autocomplete>();
  const physicalAddressInputRef = useRef<HTMLInputElement | null>(null);
  const googleAutoCompleteForMailingAddressRef = useRef<google.maps.places.Autocomplete>();
  const mailingAddressInputRef = useRef<HTMLInputElement | null>(null);

  const { data: lookups } = FetchLookups();

  const { data: legalEntityTypes } = ConfigurationService.useLegalEntityTypes();

  const { data: statesResponse } = AuthService.useStatesQuery();
  const { data: sellerGroups } = useAllSellerGroups({
    enabled: orgType === OrganizationType.Seller,
  });

  const legalOrganizationOptions = useMemo(() => {
    let result =
      legalEntityTypes?.map((item) => ({
        label: item.legalEntityTypeDisplayName,
        value: item.legalEntityTypeId,
        additionalInfo: { isActive: item.status },
      })) || [];
    if (!orgData || (orgData && orgData.legalEntityTypeId !== 6)) {
      result = result.filter((item) => item.additionalInfo.isActive);
    }

    return result;
  }, [legalEntityTypes, orgData]);

  const states = useMemo<{ label: string; value: AddressState }[]>(
    () =>
      statesResponse
        ? statesResponse
            .map((state) => ({ label: `${state.stateCode}: ${state.stateName}`, value: state }))
            .sort((a, b) => `${a.label}`.localeCompare(`${b.label}`))
        : [],
    [statesResponse],
  );

  const sellerGroupsOptions = useMemo(
    () =>
      sellerGroups?.map((sellerGroup) => ({
        label: `${sellerGroup.name}<span class="vl"></span>${normalizePhone(
          sellerGroup.phoneNumber,
        )}`,
        value: sellerGroup.id,
        additionalInfo: { legalEntityTypeId: sellerGroup.legalEntityTypeId },
      })) || [],
    [sellerGroups],
  );

  const formDefaultValues = useMemo(() => {
    const physicalAddress = orgData?.addresses?.filter((x) => x.addressTypeId === 1)[0];

    const physicalLocationStateValue = states.find(
      (stateObj) => stateObj.value.addressStateId === physicalAddress?.stateId,
    )?.value;

    const mailingAddress = orgData?.addresses?.filter((x) => x.addressTypeId === 2)[0];
    const mailingLocationStateValue = states.find(
      (stateObj) => stateObj.value.addressStateId === mailingAddress?.stateId,
    )?.value;

    let additionalFields = {};

    if (orgType === OrganizationType.Agency) {
      additionalFields = { ...additionalFields, agentId: orgData?.agentId || '' };
    }

    if (orgType === OrganizationType.Seller) {
      additionalFields = { ...additionalFields, sellerId: orgData?.customerOrgId || '' };
    }

    if (orgType === OrganizationType.Seller && orgData?.sellerGroupId) {
      const foundSellerGroup = sellerGroupsOptions.find(
        (sellerGroupItem) => sellerGroupItem.value === orgData?.sellerGroupId,
      );
      additionalFields = { ...additionalFields, sellerGroup: foundSellerGroup };
    }

    if (defaultSellerGroupId) {
      const foundSellerGroup = sellerGroupsOptions.find(
        (sellerGroupItem) => sellerGroupItem.value === +defaultSellerGroupId,
      );
      additionalFields = { ...additionalFields, sellerGroup: foundSellerGroup };
    }

    const res: OrganizationFormType = {
      legalName: orgData?.legalName || '',
      legalOrganization: orgData?.legalEntityTypeId
        ? {
            label:
              legalEntityTypes?.find(
                (item) => item.legalEntityTypeId === orgData?.legalEntityTypeId,
              )?.legalEntityTypeDisplayName || '',
            value: orgData?.legalEntityTypeId || 0,
          }
        : null,
      doingBusinessAs: orgData?.doingBusinessAs || '',
      fein:
        orgData?.legalEntityTypeId === 5
          ? normilizeSSN(orgData?.fein || '')
          : normalizeFein(orgData?.fein || ''),
      fax: physicalAddress?.mainFax?.replaceAll('-', '') || '',
      website: physicalAddress?.website || '',
      physicalLocation: {
        addressId: physicalAddress?.addressId || null,
        address1: physicalAddress?.streetAddressLine1 || '',
        address2: physicalAddress?.streetAddressLine2 || '',
        city: physicalAddress?.city || '',
        state: physicalLocationStateValue
          ? {
              label:
                `${physicalLocationStateValue?.stateCode}: ${physicalLocationStateValue?.stateName}` ||
                '',
              value: physicalLocationStateValue,
            }
          : null,
        zip: normalizeZip(physicalAddress?.zipCode || ''),
        googlePlaceId: physicalAddress?.googlePlaceId || '',
      },
      mailingLocation: {
        addressId: mailingAddress?.addressId || null,
        address1: mailingAddress?.streetAddressLine1 || '',
        address2: mailingAddress?.streetAddressLine2 || '',
        city: mailingAddress?.city || '',
        state: mailingLocationStateValue
          ? {
              label:
                `${mailingLocationStateValue?.stateCode}: ${mailingLocationStateValue?.stateName}` ||
                '',
              value: mailingLocationStateValue,
            }
          : null,
        zip: normalizeZip(mailingAddress?.zipCode || ''),
        googlePlaceId: mailingAddress?.googlePlaceId || '',
      },
      isLocationsTheSame:
        mailingAddress?.sameAsAddressTypeId === 1 || physicalAddress?.sameAsAddressTypeId === 2,
      sellerGroup: null,
      isSellerGroupFein: orgData?.feinSource ? !!FeinSource.SellerGroup : !!FeinSource.Seller,
      phone: {
        number:
          physicalAddress?.phoneNumber && (physicalAddress?.phoneNumberCountryCode || '1')
            ? (physicalAddress?.phoneNumberCountryCode || '1') + physicalAddress?.phoneNumber
            : '',
        countryCode: physicalAddress?.phoneNumberCountryCode || '1',
        ext: physicalAddress?.phoneNumberExt || '',
        isValid: true,
      },
      ...additionalFields,
    };

    return res;
  }, [orgData, defaultSellerGroupId, states, orgType, legalEntityTypes, sellerGroupsOptions]);

  const formMethods = useForm<OrganizationFormType>({
    mode: 'all',
    defaultValues: formDefaultValues,
    resolver: yupResolver(organizationValidationSchema),
  });
  const { control, handleSubmit, reset, watch, formState, setValue, setError } = useMemo(
    () => formMethods,
    [formMethods],
  );
  const { errors, isValid } = formState;

  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset]);

  const formFields = watch();

  const [
    physicalLocationAddress1,
    physicalLocationAddress2,
    physicalLocationCity,
    physicalLocationState,
    physicalLocationZip,
    isLocationsTheSame,
    selectedSellerGroup,
    selectedLegalOrganization,
    isSellerGroupFeinChecked,
  ] = watch([
    'physicalLocation.address1',
    'physicalLocation.address2',
    'physicalLocation.city',
    'physicalLocation.state',
    'physicalLocation.zip',
    'isLocationsTheSame',
    'sellerGroup',
    'legalOrganization',
    'isSellerGroupFein',
  ]);

  const { fillInAddressFromAutoComplete, fillInAddressFromValidation } = useFillGoogleAddress({
    setValue,
    states,
  });

  const saveOrganization = useCallback(() => {
    if (onSubmit) {
      onSubmit({
        ...formFields,
        physicalLocation: {
          ...formFields.physicalLocation,
          zip: formFields.physicalLocation.zip.replaceAll('-', ''),
        },
        mailingLocation: {
          ...formFields.mailingLocation,
          zip: formFields.mailingLocation.zip.replaceAll('-', ''),
        },
      });
    }
  }, [formFields, onSubmit]);

  const [validateAddress] = useValidateAddress();

  const runValidation = useCallback(
    async (data: OrganizationFormType, addressType: 'physicalLocation' | 'mailingLocation') => {
      const { verdict, missingComponents, suggestedAddress, validationResponse, enteredAddress } =
        await validateAddress({
          data: { physicalLocation: data.physicalLocation, mailingLocation: data.mailingLocation },
          addressType: addressType,
        });

      if (isLocationsTheSame) {
        setValue(`physicalLocation.googlePlaceId`, validationResponse.result.geocode.placeId);
        setValue(`mailingLocation.googlePlaceId`, validationResponse.result.geocode.placeId);
      } else {
        setValue(`${addressType}.googlePlaceId`, validationResponse.result.geocode.placeId);
      }

      switch (verdict) {
        case 'ADDRESS_COMPLETE': {
          //go to mailing or submit form
          if (!isLocationsTheSame && addressType !== 'mailingLocation') {
            await runValidation(formFields, 'mailingLocation');
          } else {
            saveOrganization();
          }
          break;
        }
        case 'CHOOSE_VALID_ADDRESS': {
          // show modal with recomended modal and go to mailing address or submit form

          setAddressValidationModalPayload({
            verdict,
            addressType,
            enteredAddress,
            missingComponents,
            suggestedAddress,
            onSubmit: async (res) => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
              if (res?.selectedAddress === 'suggested') {
                fillInAddressFromValidation(
                  validationResponse.result.address.addressComponents,
                  addressType,
                );
                if (addressType === 'physicalLocation' && isLocationsTheSame) {
                  fillInAddressFromValidation(
                    validationResponse.result.address.addressComponents,
                    'mailingLocation',
                  );
                }
                await runValidation(formFields, addressType);
              } else {
                if (!isLocationsTheSame && addressType !== 'mailingLocation') {
                  await runValidation(formFields, 'mailingLocation');
                } else {
                  saveOrganization();
                }
              }
            },
            onCancel: () => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
            },
          });

          break;
        }
        case 'MISSING_INFORMATION': {
          // show modal with missing field modal and go to mailing address or submit form
          setAddressValidationModalPayload({
            verdict,
            addressType,
            enteredAddress,
            missingComponents,
            suggestedAddress,
            onSubmit: async (res) => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
              if (res?.missingData) {
                if (res.missingData.apt) {
                  setValue(`${addressType}.address2`, res.missingData.apt.trim(), {
                    shouldValidate: true,
                  });
                }
                if (res.missingData.address1) {
                  setValue(`${addressType}.address1`, res.missingData.address1.trim(), {
                    shouldValidate: true,
                  });
                }

                const adress1 = enteredAddress.slice(0, enteredAddress.indexOf(','));

                if (
                  missingComponents.streetOne &&
                  res.missingData.address1 !== adress1.slice(0, enteredAddress.indexOf('\n'))
                ) {
                  await runValidation(formFields, addressType);
                } else if (missingComponents.streetTwo && res.missingData.apt) {
                  await runValidation(formFields, addressType);
                } else {
                  saveOrganization();
                }
              }
            },
            onCancel: () => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
            },
          });

          break;
        }
        case 'COULDNT_VALIDATE': {
          setAddressValidationModalPayload({
            verdict,
            addressType,
            enteredAddress,
            missingComponents,
            suggestedAddress,
            onSubmit: async (res) => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
              if (res?.selectedAddress === 'entered') {
                if (!isLocationsTheSame && addressType !== 'mailingLocation') {
                  await runValidation(formFields, 'mailingLocation');
                } else {
                  saveOrganization();
                }
              }
            },
            onCancel: () => {
              setAddressValidationModalPayload((prev) => ({
                ...prev,
                verdict: 'ADDRESS_COMPLETE',
              }));
            },
          });
          break;
        }
      }
    },
    [
      fillInAddressFromValidation,
      formFields,
      isLocationsTheSame,
      saveOrganization,
      setValue,
      validateAddress,
    ],
  );
  const onSubmitWrapper = async (data: OrganizationFormType) => {
    await runValidation(data, 'physicalLocation');
  };

  useEffect(() => {
    const isValuesEqual = isEqual(formDefaultValues, {
      ...formFields,
      fax: formFields.fax?.replaceAll('-', ''),
      fein: formFields.fein,
      isLocationsTheSame: !!formFields.isLocationsTheSame,
    });

    if (setIsUnsavedChanges) setIsUnsavedChanges(!isValuesEqual);
    if (setIsFormValid) setIsFormValid(isValid);
  }, [formDefaultValues, formFields, isValid, setIsFormValid, setIsUnsavedChanges]);

  useEffect(() => {
    if (customFieldError) {
      setError(customFieldError as keyof OrganizationFormType, {
        type: 'custom',
        message: getCommonErrorMessages('exists'),
      });
      if (setCustomFieldError) setCustomFieldError('');
    }
  }, [customFieldError, setCustomFieldError, setError]);

  useEffect(() => {
    if (isLocationsTheSame) {
      setValue('mailingLocation.address1', physicalLocationAddress1, { shouldValidate: true });
      setValue('mailingLocation.address2', physicalLocationAddress2, { shouldValidate: true });
      setValue('mailingLocation.city', physicalLocationCity, { shouldValidate: true });
      setValue('mailingLocation.state', physicalLocationState, { shouldValidate: true });
      setValue('mailingLocation.zip', physicalLocationZip, {
        shouldValidate: true,
      });
    }
  }, [
    setValue,
    isLocationsTheSame,
    physicalLocationAddress1,
    physicalLocationAddress2,
    physicalLocationCity,
    physicalLocationState,
    physicalLocationZip,
  ]);

  useEffect(() => {
    if (isSellerGroupFeinChecked && selectedSellerGroup) {
      const foundSellerGroup = sellerGroups?.find((item) => item.id === selectedSellerGroup?.value);
      if (foundSellerGroup && foundSellerGroup.legalEntityTypeId !== 5) {
        setValue('fein', foundSellerGroup.fein || '');
      } else {
        setValue('isSellerGroupFein', false);
        setValue('fein', '');
      }
    }
    if (selectedLegalOrganization?.value === 5 && isSellerGroupFeinChecked) {
      setValue('fein', '');
      setValue('isSellerGroupFein', false);
    }
  }, [
    isSellerGroupFeinChecked,
    selectedLegalOrganization,
    selectedSellerGroup,
    sellerGroups,
    setValue,
  ]);

  useEffect(() => {
    if (!isSellerGroupFeinChecked) {
      setValue('fein', '');
    }
  }, [isSellerGroupFeinChecked, setValue]);

  // Google autocomplete for Physical Address
  useEffect(() => {
    if (physicalAddressInputRef.current) {
      googleAutoCompleteForPhysicalAddressRef.current = new window.google.maps.places.Autocomplete(
        physicalAddressInputRef.current,
        { componentRestrictions: { country: 'us' } },
      );
    }
    if (googleAutoCompleteForPhysicalAddressRef.current) {
      googleAutoCompleteForPhysicalAddressRef.current.addListener(
        'place_changed',
        async function () {
          const place = googleAutoCompleteForPhysicalAddressRef.current?.getPlace();

          if (place) fillInAddressFromAutoComplete(place, 'physicalLocation');
        },
      );
    }
  }, [fillInAddressFromAutoComplete]);

  // Google autocomplete for Mailing Address
  useEffect(() => {
    if (mailingAddressInputRef.current) {
      googleAutoCompleteForMailingAddressRef.current = new window.google.maps.places.Autocomplete(
        mailingAddressInputRef.current,
      );
    }
    if (googleAutoCompleteForMailingAddressRef.current) {
      googleAutoCompleteForMailingAddressRef.current.addListener(
        'place_changed',
        async function () {
          const place = googleAutoCompleteForMailingAddressRef.current?.getPlace();

          if (place) fillInAddressFromAutoComplete(place, 'mailingLocation');
        },
      );
    }
  }, [fillInAddressFromAutoComplete]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmitWrapper)}
        onReset={() => {
          reset();
        }}
        ref={formRef}
        autoComplete="off"
      >
        <Grid container spacing={isSmall ? 1 : 2}>
          {orgType === OrganizationType.Seller && (
            <Grid item xs={12}>
              <Grid container spacing={isSmall ? 1 : 2}>
                <Grid item xs={6}>
                  <AppAutocompleteSingleSelect
                    control={control as unknown as Control<FieldValues>}
                    name={'sellerGroup'}
                    label={'Seller Group'}
                    options={sellerGroupsOptions}
                    disabled={
                      sellerGroupsOptions.length === 0 || !editForm || !!defaultSellerGroupId
                    }
                    onChange={(...args) => {
                      if (args[1] === 'clear') {
                        if (isSellerGroupFeinChecked) {
                          setValue('fein', '');
                        }
                        setValue('isSellerGroupFein', false);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <AlignCenterBox sx={{ height: '100%' }}>
                  <AppActionButton
                    variant="outlined"
                    disabled={!isCreateOrganizationPermission || !editForm}
                    startIcon={<AddCircleOutlineRounded />}
                    onClick={() => {
                      if (unsavedChanges) {
                        setInfoModalOpen(true);
                      } else {
                        navigate(
                          `/dashboard/SellerGroup/Add?redirectUrl=${location.pathname}&sellerId=${sellerId}`,
                        );
                      }
                    }}
                  >
                    Add Seller Group
                  </AppActionButton>
                </AlignCenterBox> */}
                </Grid>
              </Grid>
            </Grid>
          )}

          {orgType === OrganizationType.Agency && (
            <Grid item xs={12}>
              <AppFakeTextField
                label={
                  <span>
                    {(lookups?.accountOwnerLabel && lookups.accountOwnerLabel[0].value) ||
                      'AccountOwner'}
                  </span>
                }
                value={
                  <>
                    {' '}
                    {orgData?.accountOwner?.firstName && (
                      <span>{orgData?.accountOwner?.firstName}</span>
                    )}
                    {orgData?.accountOwner?.lastName && (
                      <span> {orgData?.accountOwner?.lastName}</span>
                    )}
                    {orgData?.accountOwner?.email && (
                      <span>
                        {' | '}
                        <Link
                          sx={{ textDecoration: 'none' }}
                          href={`mailto:${orgData?.accountOwner?.email}`}
                        >
                          {orgData?.accountOwner?.email}
                        </Link>
                      </span>
                    )}
                    {orgData?.accountOwner?.phoneNumber && (
                      <span>
                        {' | '}
                        <Link
                          sx={{ textDecoration: 'none' }}
                          href={`tel:${orgData?.accountOwner?.phoneNumber}`}
                        >
                          {normalizePhone(orgData.accountOwner.phoneNumber)}
                        </Link>
                      </span>
                    )}
                  </>
                }
              />
            </Grid>
          )}

          <Grid item xs={4}>
            <Controller
              control={control}
              name={'legalName'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.legalName')}
                  required={editForm}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('sellerGroupName') }}
                  errorMessage={editForm ? errors.legalName?.message : ''}
                />
              )}
            />
          </Grid>
          {/* // Specific AGENT ID field */}
          {orgType === OrganizationType.Agency && (
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'agentId'}
                render={({ field }) => (
                  <AppTextField
                    {...field}
                    label={t('organization.fieldLabel.agentId')}
                    disabled={!editForm}
                  />
                )}
              />
            </Grid>
          )}
          {orgType === OrganizationType.Seller && (
            <Grid item xs={4}>
              <Controller
                control={control}
                name={'sellerId'}
                render={({ field }) => (
                  <AppTextField {...field} label={t('organization.fieldLabel.sellerId')} disabled />
                )}
              />
            </Grid>
          )}
          {orgType === OrganizationType.SellerGroup && (
            <Grid item xs={4}>
              <AppTextField
                label={t('organization.fieldLabel.sellerGroupId')}
                value={orgData?.customerOrgCode || ''}
                disabled
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <AppAutocompleteSingleSelect
              control={control as unknown as Control<FieldValues>}
              name={'legalOrganization'}
              label={t('organization.fieldLabel.legalOrganization')}
              options={legalOrganizationOptions}
              rules={{
                required: editForm,
              }}
              disabled={!editForm}
              errorMessage={
                editForm
                  ? errors.legalOrganization?.message || errors.legalOrganization?.label?.message
                  : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={'doingBusinessAs'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.doingBusinessAs')}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('doingBusinessAsS') }}
                  errorMessage={editForm ? errors.doingBusinessAs?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={orgType === OrganizationType.Seller && selectedSellerGroup?.value ? 4 : 6}>
            {formFields.legalOrganization?.value === 5 ? ( // id of "Sole proprietorship"
              <Controller
                control={control}
                name={'fein'}
                render={({ field }) => (
                  <AppSsnField
                    {...field}
                    label={t('organization.fieldLabel.ssn')}
                    disabled={!editForm}
                    errorMessage={editForm ? errors.fein?.message : ''}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                name={'fein'}
                render={({ field }) => (
                  <AppFeinField
                    {...field}
                    label={t('organization.fieldLabel.fein')}
                    disabled={
                      !editForm || (isSellerGroupFeinChecked && !!selectedSellerGroup?.value)
                    }
                    errorMessage={editForm ? errors.fein?.message : ''}
                  />
                )}
              />
            )}
          </Grid>
          {orgType === OrganizationType.Seller && selectedSellerGroup?.value && (
            <Grid item xs={2}>
              <FormControlLabel
                sx={{ height: '100%' }}
                disabled={
                  !editForm ||
                  selectedLegalOrganization?.value === 5 ||
                  selectedSellerGroup.additionalInfo.legalEntityTypeId === 5
                }
                control={
                  <Controller
                    name={'isSellerGroupFein'}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        color={'success'}
                        checked={props.value}
                        disabled={
                          !editForm ||
                          selectedLegalOrganization?.value === 5 ||
                          selectedSellerGroup.additionalInfo.legalEntityTypeId === 5
                        }
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                label={t('organization.fieldLabel.reuseSGFein')}
              />
            </Grid>
          )}

          <Grid item xs={4}>
            <AppPhoneField required={editForm} disabled={!editForm} />
          </Grid>

          <Grid item xs={4}>
            <Controller
              control={control}
              name={'fax'}
              render={({ field }) => (
                <AppFaxField
                  {...field}
                  label={t('organization.fieldLabel.fax')}
                  errorMessage={editForm ? errors.fax?.message : ''}
                  disabled={!editForm}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'website'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.website')}
                  errorMessage={editForm ? errors.website?.message : ''}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('website') }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <SectionHeadings>{t('create.organization.headings.physical')}</SectionHeadings>
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={'physicalLocation.address1'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.address1')}
                  inputRef={physicalAddressInputRef}
                  required={editForm}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('addressLine1') }}
                  errorMessage={editForm ? errors.physicalLocation?.address1?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={'physicalLocation.address2'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.address2')}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('addressLine2') }}
                  errorMessage={editForm ? errors.physicalLocation?.address2?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'physicalLocation.city'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.city')}
                  required={editForm}
                  disabled={!editForm}
                  inputProps={{ maxLength: getCommonMaxLengthValue('city') }}
                  errorMessage={editForm ? errors.physicalLocation?.city?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <AppAutocompleteSingleSelect
              control={control as unknown as Control<FieldValues>}
              name={'physicalLocation.state'}
              label={t('organization.fieldLabel.state')}
              options={states}
              rules={{
                required: editForm,
              }}
              disabled={!editForm}
              errorMessage={
                editForm
                  ? errors.physicalLocation?.state?.message ||
                    errors.physicalLocation?.state?.label?.message
                  : ''
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'physicalLocation.zip'}
              render={({ field }) => (
                <AppZipField
                  {...field}
                  label={t('organization.fieldLabel.zip')}
                  required={editForm}
                  disabled={!editForm}
                  errorMessage={editForm ? errors.physicalLocation?.zip?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SectionHeadings>{t('create.organization.headings.mailing')}</SectionHeadings>
            <FormControlLabel
              disabled={
                !formFields.physicalLocation.address1 ||
                !formFields.physicalLocation.city ||
                !formFields.physicalLocation.state?.label ||
                !formFields.physicalLocation.zip ||
                !editForm
              }
              control={
                <Controller
                  name={'isLocationsTheSame'}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      disabled={
                        !formFields.physicalLocation.address1 ||
                        !formFields.physicalLocation.city ||
                        !formFields.physicalLocation.state?.label ||
                        !formFields.physicalLocation.zip ||
                        !editForm
                      }
                      color={'success'}
                      checked={props.value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
              }
              label={t('organization.fieldLabel.sameMailingAddress')}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={'mailingLocation.address1'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.address1')}
                  required={editForm}
                  inputRef={mailingAddressInputRef}
                  disabled={formFields.isLocationsTheSame || !editForm}
                  errorMessage={editForm ? errors.mailingLocation?.address1?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={'mailingLocation.address2'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  disabled={formFields.isLocationsTheSame || !editForm}
                  label={t('organization.fieldLabel.address2')}
                  errorMessage={editForm ? errors.mailingLocation?.address2?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'mailingLocation.city'}
              render={({ field }) => (
                <AppTextField
                  {...field}
                  label={t('organization.fieldLabel.city')}
                  disabled={formFields.isLocationsTheSame || !editForm}
                  required={editForm}
                  errorMessage={editForm ? errors.mailingLocation?.city?.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <AppAutocompleteSingleSelect
              control={control as unknown as Control<FieldValues>}
              name={'mailingLocation.state'}
              label={t('organization.fieldLabel.state')}
              options={states}
              disabled={formFields.isLocationsTheSame || !editForm}
              rules={{
                required: editForm,
              }}
              errorMessage={
                editForm
                  ? errors.mailingLocation?.state?.message ||
                    errors.mailingLocation?.state?.label?.message
                  : ''
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              control={control}
              name={'mailingLocation.zip'}
              render={({ field }) => (
                <AppZipField
                  {...field}
                  label={t('organization.fieldLabel.zip')}
                  disabled={formFields.isLocationsTheSame || !editForm}
                  required={editForm}
                  errorMessage={editForm ? errors.mailingLocation?.zip?.message : ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      {!editForm && (
        <ModificationLog
          {...{
            createdBy: orgData?.createdBy,
            createdOn: orgData?.createdOn,
            updatedBy: orgData?.updatedBy,
            updatedOn: orgData?.updatedOn,
          }}
        />
      )}
      <AddressValidationModals
        onCancel={addressValidationModalPayload?.onCancel}
        onSubmit={addressValidationModalPayload?.onSubmit}
        verdict={addressValidationModalPayload?.verdict}
        addressType={addressValidationModalPayload?.addressType}
        enteredAddress={addressValidationModalPayload?.enteredAddress}
        missingComponents={addressValidationModalPayload?.missingComponents}
        suggestedAddress={addressValidationModalPayload?.suggestedAddress}
      />
    </FormProvider>
  );
};
