import { Box } from '@mui/material';
import styled from 'styled-components';
import { MainTextColor, Platinum, SecondaryTextColor } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';

export const FakeTextFieldStyled = styled(Box)`
  height: 56px;
  background: ${Platinum};
  position: relative;
  ${breakPointMedium(`
       height: 50px;
    `)}
`;

export const FakeTextFieldLabel = styled(Box)`
  position: absolute;
  top: -12px;
  left: 14px;
  font-size: 15px;
  color: ${SecondaryTextColor};
  ${breakPointMedium(`
        font-size: 12px;
  top: -6px;

    `)}
`;

export const FakeTextFieldValue = styled(Box)`
  position: absolute;
  top: 16px;
  left: 14px;
  font-size: 16px;
  font-family: Gotham-Medium;
  color: ${MainTextColor};
  ${breakPointMedium(`
    font-size: 12px;
  top: 18px;

`)}
`;
