import { ConfigurationService } from '../../../services/configurationService';
import { Box } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';

export default function FIMenuPage() {
  const apiRef = useGridApiRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.ViewConfiguration);
  const { data: fiMenus, isFetching } = ConfigurationService.useFIMenus({
    enabled: isEnabled,
  });

  const rows = useMemo(() => {
    return (
      fiMenus?.map((item) => ({
        ...item,

        id: item.fiMenuId,
      })) || []
    );
  }, [fiMenus]);

  const columns: GridColDef[] = [
    { field: 'fiMenuName', headerName: 'F&I Menu Name' },
    { field: 'fiMenuDisplayName', headerName: 'F&I Menu Display Name' },
  ];

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns({
        includeHeaders: true,
        columns: ['fiMenuName', 'fiMenuDisplayName'],
        expand: true,
      });
    }, 500);
  }, [apiRef, rows, fiMenus]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="F&I Menu" />
      </Box>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        loading={isFetching}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        autosizeOptions={{
          includeHeaders: true,
          expand: true,
        }}
        editMode="cell"
      />
    </>
  );
}
